import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const UserTerms = () => {
  return (
    <Container
      sx={{
        backgroundColor: "#f7f9fc", // Light background to make it stand out
        padding: "16px",
        borderRadius: "8px", // Rounded corners for a modern feel
        mb: 3,
        borderLeft: "6px solid var(--primary-color-1)", // Accent color for definition box
        borderRight: "6px solid var(--primary-color-1)", // Accent color for definition box
      }}
    >
      <Box>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          SAKU Terms of Use (USERS)
        </Typography>
        <Typography variant="body1" paragraph>
          By downloading, installing, and/or using the SAKU Application, You
          agree that You have read, understood and accepted and agreed to these
          Terms and Condition (“Terms of Use”).
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms of Use constitute a legal agreement between You and SAKU
          (including affiliate) and apply to Your visit to and use of Our
          website at <a href="https://saku.my">https://saku.my</a> or other
          sites which We developed officially to support the Service ecosystem.
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          PLEASE REVIEW OUR TERMS OF USE AND PRIVACY POLICY CAREFULLY BEFORE
          DOWNLOADING SAKU APPLICATION OR USING OUR SERVICE FOR THE FIRST TIME.
        </Typography>
        <Typography variant="body1" paragraph>
          The Services available to You on the SAKU Application are so diverse
          that We can include or enforce additional Terms of Use for the purpose
          of certain Services, in which the additional terms are part of Our
          Services which are bound and inherent to these Terms of Use.
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to modify, vary and change the Terms of Use or
          Privacy Policy relating to the Services at any time as it deems fit so
          that You are required from time to time read Our Terms of
        </Typography>
        <Typography variant="body1" paragraph>
          Use and Privacy Policy in the SAKU Application or Our website. These
          Terms of Use apply to You nor for Your use of the Service by or
          through Your account wherever you may be, whether in the states and
          federal territories of Malaysia or in any other countries.
        </Typography>

        {/* Definition Section */}
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          FOR THE AVOIDANCE OF DOUBT, WE ARE A SOFTWARE AS A SERVICE (SAAS)
          COMPANY WHICH PROVIDES PLATFORM FOR EACH SERVICES WE PROVIDE AND NOT A
          PUBLISHER OF ELECTRONIC MONEY.
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          1. DEFINITION
        </Typography>
        <Typography variant="body1" paragraph>
          In this Terms of Use, unless expressly provided otherwise, the
          following expressions have the following meanings:
        </Typography>
        <List sx={{ paddingLeft: 2 }}>
          <ListItem disableGutters>
            <ListItemText primary="“You” means the party registered as a SAKU Application user to use the Services provided in the SAKU Application. " />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="“Affiliate” means, in relation to a party, any entity that controls, is under the control of, or is under common control with or the legal power to direct or cause the direction of the general management and policies of that party. " />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText
              primary="“SAKUBiz Application” is a mobile app with the name SAKUBiz for
          Loyalty Memberships, and its derivative which can be downloaded via
          playstore for Android users as well as through via app store for iOS
          users that We develop to support Our Services provided."
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText
              primary="“Personal Information” means information regarding Yourself which can
          be privately identified and collected through the SAKUBiz Application,
          such as name, address, date of birth and occupation (if You are a
          person), corporate data and identity document (if You are not a
          person), phone number, email address and other information which may
          identify You as an user of SAKUBiz Application."
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText
              primary="“We/Our/Us” means SAKUBiz (including affiliate) a company established
          under the laws of the states and federal of Malaysia."
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText
              primary="“Terms of Use” means terms and condition or standard operational
          procedures or other terms in connection with SAKUBiz Application or
          website developed by Us."
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="“Service” means various services offered by SAKUBiz Application or website. " />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText
              primary="“Force Majeure” means any cause which is not reasonably within the
          control of the person affected including, without limitation, an act
          of God, an industrial dispute, war, blockade, disturbance, lightning,
          fire, earthquake, storm, flood, explosion or meteor, restraint of a
          statutory authority, expropriation, prohibition, unavailability or
          delay in availability of equipment or transport."
            />
          </ListItem>
          <ListItem disableGutters>
            <Typography
              variant="body1"
              sx={{ lineHeight: 1.6 }}
              dangerouslySetInnerHTML={{
                __html: `“Customer Service” is the customer service center We provide for You
          and can be reached by email <a href='mailto:info@saku.my'>info@saku.my</a> and can be used when You have
          problems in the use of SAKUBiz Application or when you use Our
          Service."`,
              }}
            />
          </ListItem>
          <ListItem disableGutters>
            <Typography
              variant="body1"
              sx={{ lineHeight: 1.6 }}
              dangerouslySetInnerHTML={{
                __html: `“Website” means Our website at <a href='https://saku.my' target='_blank' rel='noopener noreferrer'>https://saku.my</a> or other sites which We developed officially to support the Service ecosystem.`,
              }}
            />
          </ListItem>
        </List>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          2. TERMS FOR USING THE APPLICATION
        </Typography>
        <Typography variant="body1" paragraph>
          In this Terms of Use, unless expressly provided otherwise, the
          following expressions have the following meanings:
        </Typography>
        <List sx={{ paddingLeft: 2 }}>
          <ListItem disableGutters>
            <ListItemText primary="You represent and warrant that You are an individual legally entitled to enter into a binding agreement under the laws of the states and federal of Malaysia, in particular the Terms of Use, to use the SAKU Application. Otherwise, We will be entitled by law to cancel any agreement entered into with You. You further represent and warrant that You have the right, authority and capacity to use the Service and to abide by the Terms of Use. " />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="We collect and process your Personal Information, such as Your name, address, date of birth, and occupation (if You are a person), corporate identity documents (if You are not a person), mobile number, email address and other information who may be able to identify You as an User of SAKU Application. You must provide accurate and complete information, keep the information up to date and agree to provide Us whatever proof of identity We may reasonably ask for. " />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="You can only use the SAKU Application once You have signed up with it. Upon successfully signing up, the SAKU Application will provide You with a personal account that can be accessed with a mobile phone number. " />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="Only You may use Your own account and You undertake not to authorize others to use Your identity or account. You may not assign or otherwise transfer Your account to any other parties. You must keep the security and confidentiality of Your account and any identification We provide to You. " />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="You undertake that You will use the Application only for its intended use to obtain the Service. You may not abuse or use the SAKU Application for fraudulent purposes or to cause any inconvenience to others. " />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="You understand and agree that Your use of the SAKU Application will also be subject to Our Privacy Policy as may be amended from time to time. By using the SAKU Application, You also give Your consent as required under our Privacy Policy. " />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="Please note that You are responsible for the use of Your account and may be held liable even if it is abused by another person. " />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="You understand and agree by downloading, installing, and/or using the SAKU Application, you are allowing the use of your phone number that is registered in SAKU Application (both phone number that you registered at first and the phone number that We got from other parties who had collaborated with Us) as Our communication method in accordance with the Privacy Policy that we have  set before. " />
          </ListItem>
        </List>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          3. THE MATTERS RELATING TO PERSONAL INFORMATION
        </Typography>
        <Typography variant="body1" paragraph>
          You hereby agree that We may collect, store, process, disclose
          information access, review and / or use personal information about
          you. We ensure that all personal information submitted by you through
          the SAKU Application and / or our website is well protected and
          encrypted.  We agree to keep all personal information private and not
          to use such personal information for any purpose other than for
          matters related to our Services. However, this does not apply to:
        </Typography>
        <List sx={{ paddingLeft: 2 }}>
          <ListItem disableGutters>
            <ListItemText primary="Prevention of fraudulent acts and / or matters relating to anti money laundering and criminal acts of terrorism; " />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="Personal information that is generally known to the public; and " />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="Such personal information is required to be disclosed by law, government or authorized institution under existing regulations. " />
          </ListItem>
        </List>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          4. THE MATTERS RELATING TO PERSONAL INFORMATION
        </Typography>
        <Typography variant="body1" paragraph>
          You may only access the Service using authorized means. It is Your
          responsibility to check and ensure that You have downloaded the
          correct SAKU Application for Your device. We are not liable if
        </Typography>
        <Typography variant="body1" paragraph>
          You do not have a compatible device or if You have downloaded the
          wrong version of the SAKU Application to Your device. {" "}
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the rights not to permit You to use the Service should You
          use the SAKU Application with an incompatible or unauthorized device
          or for purpose other than which the SAKU Application is intended to be
          used.{" "}
        </Typography>

        <Typography variant="body1" paragraph>
          We do not represent or warrant that:
        </Typography>
        <List sx={{ listStyleType: "disc", pl: 4 }}>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="The use of the Services and/or SAKU Application will be secured, timely, uninterrupted or error-free or operate in combination with any other hardware, software, system or data; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="Any stored data will be accurate or reliable; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="The services will meet Your requirements or expectations; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="The SAKU Application or the server(s) that make the Service are free of viruses or other harmful components. " />
          </ListItem>
        </List>
        <Typography variant="body1" paragraph>
          By using the SAKU Application, You agree that:
        </Typography>
        <List sx={{ listStyleType: "disc", pl: 4 }}>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will only use the Service for lawful purposes; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will only use the Service for the purpose for which it is intended to be used; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will not use the SAKU Application for fraudulent purposes; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will not use the Service and SAKU Application for purposes other than obtaining the Service; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will not try to harm the Service and SAKU Application in any way whatsoever; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will only use the SAKU Application for Your own use; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will keep secure and confidential Your account password or any identification We provide You which allows access to the Service; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will provide the Company with proof of identity as it may reasonably request or require; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You agree to provide accurate, current and complete information as required for the Service and undertake the responsibility to maintain and update Your information in a timely manner to keep it accurate, current and complete at all times during the Terms of Use. You agree that We may rely on Your information as accurate, current and complete. You acknowledge that if Your information is untrue, inaccurate, not current or incomplete in any respect, We have the right but not the obligation to terminate this Terms of Use and Your use of the Service at any time with or without notice; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will only use an access point or data account which You are authorized to use; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You agree that the Service is provided on a reasonable effort basis; and " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You agree that Your use of the Service will be subject to the Our Privacy Policy as may be amended from time to time.  " />
          </ListItem>
        </List>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          5. OUR LIABILITIES 
        </Typography>
        <Typography variant="body1" paragraph>
          We employ appropriate and reasonable technical and security measures
          to keep SAKU Application secure and free from virus and error.
          However, as effective as this technology is, no security system is
          impenetrable. We therefore cannot guarantee the security of Our
          database nor can We guarantee that the information You provide will
          not be intercepted while being transmitted to Us.
        </Typography>
        <Typography variant="body1" paragraph>
          The SAKU Application may be subject to limitations, delays, and other
          problems inherent in the use of the internet and electronic
          communications including the device used by You being faulty, not
          connected, out of range, switched off or not functioning. We are not
          responsible for any delays, delivery failures, damages or losses
          resulting from such problems.
        </Typography>
        <Typography variant="body1" paragraph>
          Any problems arising from the use of SAKU Application and/or Our
          website may be reported to Us through the available Customer Service.
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          6. INTELLECTUAL PROPERTY MATTERS
        </Typography>
        <Typography variant="body1" paragraph>
          SAKU, including its name and logo, SAKU Application and the Service,
          is protected by copyright, trademark and other rights provided under
          the laws of the states and federal of Malaysia. We exclusively own all
          rights, title and interest in and to the SAKU Application, including
          all associated intellectual property rights.
        </Typography>
        <Typography variant="body1" paragraph>
          Without derogating from Our rights under any applicable law or these
          Terms of Use, You are advised that any attempted or actual
          infringement of this provision will result in the termination of all
          Your rights under these Terms of Use. If You circumvent any of the
          means taken by Us to protect the Service from unauthorized use, You
          must immediately cease any and all use of the Service, and You
          undertake to do so.  
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          7. TERMINATION 
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to immediately suspend, restrict or terminate
          these Terms of Use and Your use of the SAKU Application if We have
          reasonable grounds to suspect that You have breached or violated any
          provision of these Terms of Use or the prevailing laws and
          regulations. 
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          8. MISCELLANEOUS
        </Typography>
        <Typography variant="body1" paragraph>
          Our waiver or forbearance of failure to claim a breach of any
          provision of these Terms of Use or to exercise any right provided by
          these Terms of Use or the applicable law, may not be deemed to
          constitute a waiver with respect to any subsequent breach of any
          provision hereof.{" "}
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms of Use are drawn up, which versions will be binding on You
          and Us. 
        </Typography>
        <Typography variant="body1" paragraph>
          You may not transfer or assign Your rights under these Terms of Use,
          without our prior written approval. 
        </Typography>
        <Typography variant="body1" paragraph>
          If any terms under these Terms of Use is held to be illegal, invalid
          or unenforceable, in whole or in part, under any enactment or rule of
          law, the term or part of it will, to that extent, be deemed not to
          form part of these Terms of Use but the legality, validity or
          enforceability of the remainder of these Terms of Use will not be
          affected.
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms of Use are governed by and to be construed under the laws
          of any jurisdiction where the SAKU Application is used. Any disputes
          arising from the Terms of Use shall be resolved by Arbitration under
          Malaysia’s arbitration rules – Asian International Arbitration Center
          (AIAC).
        </Typography>
      </Box>
    </Container>
  );
};

export default UserTerms;
