import * as React from "react";
import { Stack, Typography, Box, Paper, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EmailIcon from "@mui/icons-material/Email";

export default function PaymentSuccess() {
  const handleContactSupport = () => {
    const email = "support@saku.my";
    window.location.href = `mailto:${email}`;
  };

  return (
    <Stack
      spacing={4}
      sx={{ p: 4, textAlign: "center", maxWidth: 800, mx: "auto" }}
    >
      <Paper
        elevation={4}
        sx={{ p: 4, backgroundColor: "#e8f0fe", borderRadius: 3 }}
      >
        <Stack spacing={3} alignItems="center">
          <CheckCircleIcon
            fontSize="large"
            color="success"
            sx={{ fontSize: 80 }}
          />
          <Typography variant="h4" color="textPrimary">
            Thank you for your payment!
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            We’re excited to have you with us. To get started, please check your
            email for the next steps. Inside, you’ll find instructions on how to
            log in and explore all the features we offer.
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            If you don’t see the email in your inbox, be sure to check your spam
            or junk folder just in case. Should you need any assistance, our
            support team is ready to help{" "}
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              onClick={handleContactSupport}
              startIcon={<EmailIcon />}
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
            >
              Contact Support
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
}
