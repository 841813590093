import { memo } from "react";
import OurMissionAndVision from "./OurMissionAndVision";
import PropTypes from "prop-types";
import styles from "./SakuAboutUs.module.css";

const SakuAboutUs = memo(({ className = "" }) => {
  return (
    <div className={[styles.sakuaboutus, className].join(" ")}>
      <section className={styles.heroActions}>
        <div className={styles.textContentTitle}>
          <h1 className={styles.title}>About us</h1>
          <div className={styles.subtitle}>
            <p className={styles.atSakuWe}>
              At saku, we are a collection of passionate and experienced
              individuals commited to user-centric experiences and problem
              solving solutions – especially those who are unserved /
              underserved.
            </p>
            <p className={styles.atSakuWe}>&nbsp;</p>
            <p className={styles.atSakuWe}>
              We make center around 4 pillars which are to be simple,
              convenient, inclusive and most of all responsive.
            </p>
            <p className={styles.atSakuWe}>&nbsp;</p>
            <p className={styles.atSakuWe}>
              Our lean-mean-team is always excited to redefine and rediscover
              things, but it is the diversity that helps us stay innovative and
              grounded in our approach – which mirrors the marketplace where
              everyone has different wants, needs and ideas on how to approach
              loyalty- so watch this space as we constantly evolve and upgrade!
            </p>
          </div>
        </div>
        <img
          className={styles.heroActionsChild}
          
          alt=""
          src="/group-4.webp"
        />
      </section>
      <OurMissionAndVision />
    </div>
  );
});

SakuAboutUs.propTypes = {
  className: PropTypes.string,
};

export default SakuAboutUs;
