import React, { useEffect, useRef, useState } from "react";
import { Container, Grid, Typography, Tabs, Tab } from "@mui/material";
import { useSpring, animated } from "react-spring";
import { useLocation } from "react-router-dom";
import UserTerms from "./UserTerms";
import MerchantTerms from "./MerchantTerms";

const UserAndMerchantTerms = () => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);
  const headingRef = useRef(null);

  useEffect(() => {
    // Convert the pathname to lowercase for case-insensitive comparison
    const pathname = location.pathname.toLowerCase();
    // Set the selected tab based on the current path
    switch (pathname) {
      case "/merchant-terms":
        setSelectedTab(1);
        break;
      case "/user-terms":
        setSelectedTab(0);
        break;
      default:
        setSelectedTab(0);
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
  });

  const slideIn = useSpring({
    from: { transform: "translateY(20px)", opacity: 0 },
    to: { transform: "translateY(0)", opacity: 1 },
    config: { tension: 170, friction: 26 },
  });

  return (
    <Container
      sx={{
        pt: { xs: 4, sm: 4 },
        pb: { xs: 4, sm: 4 },
      }}
    >
      <animated.div style={fadeIn}>
        <Typography
          ref={headingRef}
          variant="h3"
          gutterBottom
          align="center"
          style={{ color: "var(--secondary-color-3)" }}
        >
          Terms & Conditions
        </Typography>
      </animated.div>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        centered
        style={{ color: "var(--secondary-color-3)" }}
        TabIndicatorProps={{
          style: { backgroundColor: "var(--primary-color-1)" },
        }}
      >
        <Tab
          label="User Terms"
          sx={{
            fontSize: "1.25rem",
            color: "var(--secondary-color-3)",
            "&.Mui-selected": {
              color: "var(--primary-color-1)",
            },
          }}
        />
        <Tab
          label="Merchant Terms"
          sx={{
            fontSize: "1.25rem",
            color: "var(--secondary-color-3)",
            "&.Mui-selected": {
              color: "var(--primary-color-1)",
            },
          }}
        />
      </Tabs>
      <Grid container spacing={3} style={{ marginTop: "10px" }}>
        <Grid item xs={12}>
          <animated.div style={slideIn}>
            {selectedTab === 0 && <UserTerms />}
            {selectedTab === 1 && <MerchantTerms />}
          </animated.div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserAndMerchantTerms;
