// Payment.js
import React, { useState } from "react";
import {
  Button,
  Container,
  Typography,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment"; // Import an icon for the payment button
import axios from "axios"; // Import axios for API requests
import Swal from "sweetalert2";
import useReCaptcha from "../UseReCaptcha";

const REACT_APP_SAKU_WEBSITEAPI_CREATEPAYMENTFORMURL =
  process.env.REACT_APP_SAKU_WEBSITEAPI_CREATEPAYMENTFORMURL;
const REACT_APP_SAKU_WEBSITEAPI_APIKEY = process.env.REACT_APP_SAKU_WEBSITEAPI_APIKEY;
const REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY =
  process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
const REACT_APP_GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const Payment = ({ formData }) => {
  const { handleReCaptchaVerify } = useReCaptcha();
  const totalAmount = formData.totalAmount;
  const [loading, setLoading] = useState(false);

  const handlePaymentClick = async () => {
    if (isNaN(totalAmount) || totalAmount <= 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid Total Amount. Please try again.",
      });
    } else if (
      isNaN(formData.clientPackageID) ||
      formData.clientPackageID <= 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid Package. Please try again.",
      });
    } else {
      setLoading(true);
      try {
        // Step 1: Get reCAPTCHA token
        const token = await handleReCaptchaVerify();
        //console.log(token);
        const response = await axios.post(
          `https://recaptchaenterprise.googleapis.com/v1/projects/pacific-ethos-430909-c2/assessments?key=${REACT_APP_GOOGLE_API_KEY}`,
          {
            event: {
              token: token,
              siteKey: REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, // Replace with your reCAPTCHA site key
            },
          }
        );

        const { token: assessmentToken, riskAnalysis } = response.data;
        const success = riskAnalysis.score >= 0.5; // Adjust the score threshold as needed

        //console.log(riskAnalysis.score);
        //console.log(success);

        if (!success) {
          // Adjust the score threshold as needed
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "reCAPTCHA verification failed. Please try again.",
          });
          setLoading(false);
          return; // Exit the function early
        }

        if (token) {
          const response = await axios.post(
            REACT_APP_SAKU_WEBSITEAPI_CREATEPAYMENTFORMURL,
            { ...formData, recaptchaToken: token }, // Include reCaptcha token in the form data
            {
              headers: {
                "Authorization-Key": REACT_APP_SAKU_WEBSITEAPI_APIKEY, // Replace with your actual key
                "Content-Type": "application/json",
              },
            }
          );

          if (response.data.responseCode === "00") {
            const { paymentUrl } = response.data.responseData;
            try {
              // Validate the URL
              new URL(paymentUrl);
              // If valid, navigate to the URL in the same tab
              window.location.href = paymentUrl;
            } catch (e) {
              // If the URL is not valid, set an error message
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Invalid payment URL. Please try again.",
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Payment request failed. Please try again.",
            });
          }
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred. Please try again.",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // Ensure totalAmount is a number and is >= 0
  const isValidAmount = !isNaN(totalAmount) && totalAmount >= 0;

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 4, textAlign: "center" }}>
        <Typography variant="h4" gutterBottom>
          Payment Information
        </Typography>
        <Box sx={{ marginBottom: 4 }}>
          <Typography variant="h6">
            {isValidAmount ? (
              <span>Total Amount: RM {totalAmount.toFixed(2)}</span>
            ) : (
              <span>Invalid Amount</span>
            )}
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={
            loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <PaymentIcon />
            )
          }
          onClick={handlePaymentClick}
          sx={{ width: "100%" }}
          disabled={loading}
        >
          {loading ? "Processing..." : "Make Payment"}
        </Button>
      </Paper>
    </Container>
  );
};

export default Payment;
