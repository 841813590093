import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useSpring, animated } from "react-spring";
import gsap from "gsap";
import { faqData } from "./FAQData";
import { useLocation } from "react-router-dom";

const FAQComponent = () => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(0);
  const headingRef = useRef(null);

  // useEffect(() => {
  //   if (headingRef.current) {
  //     gsap.to(headingRef.current, {
  //       duration: 2,
  //       color: "#ff5722",
  //       repeat: -1,
  //       yoyo: true,
  //       ease: "power1.inOut",
  //       stagger: {
  //         amount: 1,
  //         from: "start",
  //       },
  //     });
  //   }
  // }, []);

  useEffect(() => {
    // Check the current URL path and set the appropriate tab
    if (
      location.pathname != null &&
      location.pathname.toLowerCase() === "/merchantfaq"
    ) {
      setSelectedTab(1);
    } else {
      setSelectedTab(0);
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
  });

  const slideIn = useSpring({
    from: { transform: "translateY(20px)", opacity: 0 },
    to: { transform: "translateY(0)", opacity: 1 },
    config: { tension: 170, friction: 26 },
  });

  const renderFAQs = (faqs) => {
    return (
      <Grid container spacing={3}>
        {faqs.map((faq, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Accordion
              sx={{
                "&:after": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  width: "100%",
                  height: "2px",
                  backgroundColor: "var(--secondary-color-3)",
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDownwardIcon
                    style={{ color: "var(--secondary-color-3)" }}
                  />
                }
              >
                <Typography
                  variant="body1"
                  style={{ color: "var(--secondary-color-3)" }}
                  dangerouslySetInnerHTML={{ __html: faq.question }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Container
      sx={{
        pt: { xs: 4, sm: 4 },
        pb: { xs: 4, sm: 4 },
      }}
    >
      <animated.div style={fadeIn}>
        <Typography
          ref={headingRef}
          variant="h3"
          gutterBottom
          align="center"
          style={{ color: "var(--secondary-color-3)" }}
        >
          Frequently Asked Questions
        </Typography>
      </animated.div>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        centered
        style={{ color: "var(--secondary-color-3)" }}
        TabIndicatorProps={{
          style: { backgroundColor: "var(--primary-color-1)" },
        }}
      >
        <Tab
          label="User FAQs"
          sx={{
            fontSize: "1.25rem",
            color: "var(--secondary-color-3)",
            "&.Mui-selected": {
              color: "var(--primary-color-1)",
            },
          }}
        />
        <Tab
          label="Merchant FAQs"
          sx={{
            fontSize: "1.25rem",
            color: "var(--secondary-color-3)",
            "&.Mui-selected": {
              color: "var(--primary-color-1)",
            },
          }}
        />
      </Tabs>
      <Grid container spacing={3} style={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <animated.div style={slideIn}>
            {selectedTab === 0 && renderFAQs(faqData.user)}
            {selectedTab === 1 && renderFAQs(faqData.merchant)}
          </animated.div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FAQComponent;
