import React, { useEffect, useState } from "react";

const Blog = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Prevent body scroll and reset margins/padding
    document.body.style.overflow = "hidden";
    document.body.style.margin = "0";
    document.body.style.padding = "0";
    document.documentElement.style.margin = "0";
    document.documentElement.style.padding = "0";

    // Function to detect if the device is mobile
    const handleResize = () => {
      const isMobileDevice = window.innerWidth <= 800; // Adjust this based on your mobile breakpoint
      setIsMobile(isMobileDevice);
    };

    // Set initial device state and update on resize
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      // Cleanup: reset styles when component unmounts
      document.body.style.overflow = "auto";
      document.body.style.margin = "";
      document.body.style.padding = "";
      document.documentElement.style.margin = "";
      document.documentElement.style.padding = "";
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const headerHeight = 80; // Adjust based on your header height

  return (
    <div
      style={{
        width: "100vw", // Full width of the viewport
        height: `calc(100vh - ${headerHeight}px)`, // Full height minus the header height
        marginTop: `${headerHeight}px`, // Adjust for the fixed header
        overflow: "hidden", // Ensure no scrollbars appear
        boxSizing: "border-box", // Ensure padding and borders are included in the element's width and height
      }}
    >
      <iframe
        src="https://blog.saku.my/"
        title="Blog"
        style={{
          width: "100%", // Full width of the container
          height: "100%", // Full height of the container
          border: "none", // Remove default border
          display: "block", // Prevent inline-block spacing issues
          boxSizing: "border-box", // Avoid extra padding causing space
        }}
      />
    </div>
  );
};

export default Blog;
