import * as React from "react";
import { Stack, Typography, Paper, Button } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PendingIcon from "@mui/icons-material/Pending";

export default function PaymentSuccess() {
  const handleContactSupport = () => {
    const email = "support@saku.my";
    window.location.href = `mailto:${email}`;
  };

  return (
    <Stack
      spacing={4}
      sx={{ p: 4, textAlign: "center", maxWidth: 800, mx: "auto" }}
    >
      <Paper
        elevation={4}
        sx={{ p: 4, backgroundColor: "#e8f0fe", borderRadius: 3 }}
      >
        <Stack spacing={3} alignItems="center">
          <PendingIcon fontSize="large" color="info" sx={{ fontSize: 80 }} />
          <Typography variant="h4" color="textPrimary">
            Thank you for signing up! Your payment is currently being processed.
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            We’ve received your sign-up details, and your payment is in
            progress. This may take a few moments to complete. Please check your
            email for an update on the payment status.
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Once the payment is confirmed, we’ll send you instructions on how to
            log in and start using Saku. If you have any questions or concerns
            in the meantime, our support team is here to assist you.
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              onClick={handleContactSupport}
              startIcon={<EmailIcon />}
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
            >
              Contact Support
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
}
