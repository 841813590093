import { memo } from "react";
import PropTypes from "prop-types";
import styles from "./ToBeTheSakuForLoyalty.module.css";

const ToBeTheSakuForLoyalty = memo(({ className = "" }) => {
  return (
    <div className={[styles.tile, className].join(" ")}>
      <div className={styles.text}>
        <div className={styles.textContentTitleWrapper}>
          <div className={styles.textContentTitle}>
            <h1 className={styles.title}>
              <p className={styles.toBeThe}>To be the</p>
              <p className={styles.toBeThe}>Saku for loyalty</p>
            </h1>
            <h1 className={styles.subtitle}>
              Which basically means a loyalty wallet that is for everyone –
              merchant / partner and also the user
            </h1>
          </div>
        </div>
      </div>
      <div className={styles.graphics}>
        <img
          className={styles.graphicsIcon}
          
          alt=""
          src="/graphics1.webp"
        />
      </div>
    </div>
  );
});

ToBeTheSakuForLoyalty.propTypes = {
  className: PropTypes.string,
};

export default ToBeTheSakuForLoyalty;
