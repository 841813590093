import { memo } from "react";
import PropTypes from "prop-types";
import styles from "./GrowYourCustomersBaseEngageWithThem.module.css";

const GrowYourCustomersBaseEngageWithThem = memo(({ className = "" }) => {
  return (
    <section className={[styles.section, className].join(" ")}>
      <div className={styles.textTitlePage}>
        <h1
          className={styles.textTitlePage1}
        >{`Grow your customers base & engage with them`}</h1>
      </div>
      <div className={styles.textTitlePage2}>
        <h3 className={styles.textTitlePage3}>
          Your customers also need meaningful engagement and communication.
        </h3>
      </div>
      <img
        className={styles.graphicIcon}
        
        alt=""
        src="/graphic-21@2x.webp"
      />
    </section>
  );
});

GrowYourCustomersBaseEngageWithThem.propTypes = {
  className: PropTypes.string,
};

export default GrowYourCustomersBaseEngageWithThem;
