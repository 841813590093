import { memo, useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";

const Footer = memo(
  ({
    className = "",
    footerWidth,
    footerPadding,
    footerAlignSelf,
    titleFlex,
    titleWidth,
    pocket,
    saku,
  }) => {
    const footerStyle = useMemo(() => {
      return {
        width: footerWidth,
        padding: footerPadding,
        alignSelf: footerAlignSelf,
      };
    }, [footerWidth, footerPadding, footerAlignSelf]);

    const title1Style = useMemo(() => {
      return {
        flex: titleFlex,
        width: titleWidth,
      };
    }, [titleFlex, titleWidth]);

    return (
      <section
        className={[styles.footer, className].join(" ")}
        style={footerStyle}
      >
        <div className={styles.title} style={title1Style}>
          <div className={styles.text}>
            <div className={styles.figma}>
              <div className={styles.sakuLogo}>
                <img
                  className={styles.pocketIcon}
                  
                  alt=""
                  src="/pocket.svg"
                />
                <div className={styles.sakuWrapper}>
                  <img
                    className={styles.sakuIcon}
                    
                    alt=""
                    src="/saku.svg"
                  />
                </div>
                <div className={styles.divider}>|</div>
                <div className={styles.sakuWrapper}>
                  <img
                    className={styles.sakuIcon}
                    alt=""
                    src="/md-logo.webp"
                  />
                </div>
              </div>
            </div>
            <div className={styles.text1}>
              <div className={styles.copyrightDetails}>
                <p
                  className={styles.sakuIsALoyalty}
                >{`Saku is a Loyalty Program App and it serves as a Platform-As-A-Service for customers and merchants. `}</p>
                <p
                  className={styles.sakuIsALoyalty}
                >{`We empower businesses to execute their loyalty strategy to grow long-term customer relationships, `}</p>
                <p className={styles.sakuIsALoyalty}>
                  using our proven customer loyalty program.
                </p>
              </div>
            </div>
            <div className={styles.copyright}>
              <div className={styles.text2}>
                <div className={styles.copyrightLinkItems}>
                  © 2024 Propagate Technologies Sdn Bhd (749961-A)
                </div>
              </div>
              <div className={styles.textLink}>
                <div className={styles.textLink1}>
                  <Link to="/Privacy-Policy">Privacy Policy</Link>
                </div>
              </div>
              <div className={styles.textLink}>
                <div className={styles.textLink3}>
                  <Link to="/User-Terms">Terms & Conditions</Link>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footerInfo}>
            <div className={styles.socialMedia}>
              <a href="https://download.saku.my/" target="_blank" aria-label="Download Saku App">
                <img
                  className={styles.playStoreImage}
                  
                  alt=""
                  src="/icon-google-play.webp"
                />
              </a>
              <a href="https://download.saku.my/" target="_blank" aria-label="Download Saku App">
                <img
                  className={styles.appStoreImage}
                  
                  alt=""
                  src="/icon-app-store.webp"
                />
              </a>
              <a href="https://www.facebook.com/saku.rewards" target="_blank" aria-label="Visit our Facebook page">
                <img
                  className={styles.facebookIcon}
                  
                  alt=""
                  src="/001facebook.svg"
                />
              </a>
              <a href="https://www.instagram.com/saku.rewards/" target="_blank" aria-label="Visit our Instagram profile">
                <img
                  className={styles.facebookIcon}
                  
                  alt=""
                  src="/003instagram.svg"
                />
              </a>
            </div>
            <img
              className={styles.sakuPocket1}
              
              alt=""
              src="/saku-pocket-1.svg"
            />
          </div>
        </div>
        <div className={styles.textLinkList}>
          <div className={styles.title1}>
            <div className={styles.textStrong}>
              <div className={styles.textStrong1}>Use cases</div>
            </div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem}>UI design</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem}>UX design</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem2}>Wireframing</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem3}>Diagramming</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem3}>Brainstorming</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem}>Online whiteboard</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem}>Team collaboration</div>
          </div>
        </div>
        <div className={styles.textLinkList}>
          <div className={styles.title1}>
            <div className={styles.textStrong}>
              <div className={styles.textStrong1}>Explore</div>
            </div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem}>Design</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem8}>Prototyping</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem}>Development features</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem}>Design systems</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem}>Collaboration features</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem}>Design process</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem3}>FigJam</div>
          </div>
        </div>
        <div className={styles.textLinkList}>
          <div className={styles.title1}>
            <div className={styles.textStrong}>
              <div className={styles.textStrong1}>Resources</div>
            </div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem}>Blog</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem}>Best practices</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem3}>Colors</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem8}>Color wheel</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem}>Support</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem}>Developers</div>
          </div>
          <div className={styles.textLinkListItem}>
            <div className={styles.listItem}>Resource library</div>
          </div>
        </div>
      </section>
    );
  }
);

Footer.propTypes = {
  className: PropTypes.string,
  pocket: PropTypes.string,
  saku: PropTypes.string,

  /** Style props */
  footerWidth: PropTypes.any,
  footerPadding: PropTypes.any,
  footerAlignSelf: PropTypes.any,
  titleFlex: PropTypes.any,
  titleWidth: PropTypes.any,
};

export default Footer;
