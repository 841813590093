import React from "react";
import {
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import {
  EmojiPeople as EmojiPeopleIcon,
  Email as EmailIcon,
  Business as BusinessIcon,
  Storefront as StorefrontIcon,
  Code as CodeIcon,
  Numbers as NumbersIcon,
} from "@mui/icons-material";
import { MuiTelInput } from "mui-tel-input";

const ClientInfo = ({
  formData,
  handleInputChange,
  errors,
  handleCheckboxChange,
}) => {
  const {
    companyName,
    brandName,
    totalOutlet,
    customerName,
    customerPhone,
    customerEmail,
    referralCode,
    agreeToTerms,
  } = formData;

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Business Registration Name"
          fullWidth
          name="companyName"
          value={companyName}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: <BusinessIcon color="action" sx={{ mr: 1 }} />,
          }}
          error={!!errors.companyName}
          helperText={errors.companyName}
        />
        <TextField
          label="Brand Name"
          fullWidth
          name="brandName"
          value={brandName}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: <StorefrontIcon color="action" sx={{ mr: 1 }} />,
          }}
          error={!!errors.brandName}
          helperText={errors.brandName}
        />
        <TextField
          type="number"
          label="Total Outlet"
          fullWidth
          name="totalOutlet"
          value={totalOutlet}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: <NumbersIcon color="action" sx={{ mr: 1 }} />,
          }}
          error={!!errors.totalOutlet}
          helperText={errors.totalOutlet}
          inputProps={{
            min: 1,
            max: 20,
            step: 1, // Ensures the input accepts only whole numbers
          }}
        />
        <TextField
          label="Referral Code"
          fullWidth
          name="referralCode"
          value={referralCode}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: <CodeIcon color="action" sx={{ mr: 1 }} />,
            inputProps: {
              maxLength: 6, // Limit input to 6 characters
            },
          }}
          error={!!errors.referralCode}
          helperText={errors.referralCode}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Contact Person"
          fullWidth
          name="customerName"
          value={customerName}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: <EmojiPeopleIcon color="action" sx={{ mr: 1 }} />,
          }}
          error={!!errors.customerName}
          helperText={errors.customerName}
        />
        <MuiTelInput
          label="Contact Phone"
          fullWidth
          name="customerPhone"
          value={customerPhone}
          onChange={(phone) =>
            handleInputChange({
              target: { name: "customerPhone", value: phone },
            })
          }
          sx={{
            mb: 2,
            "& .MuiIconButton-root": {
              padding: "0px",
            },
          }}
          variant="outlined"
          size="small"
          defaultCountry="MY"
          error={!!errors.customerPhone}
          helperText={errors.customerPhone}
        />
        <TextField
          label="Contact Email"
          fullWidth
          name="customerEmail"
          value={customerEmail}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: <EmailIcon color="action" sx={{ mr: 1 }} />,
          }}
          error={!!errors.customerEmail}
          helperText={errors.customerEmail}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreeToTerms}
              onChange={handleCheckboxChange}
              name="agreeToTerms"
              color="primary"
            />
          }
          label={
            <Typography variant="body2">
              I agree to the{" "}
              <a
                href="https://saku.my/Merchant-Terms"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "inherit", textDecoration: "underline" }}
              >
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a
                href="https://saku.my/Privacy-Policy"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "inherit", textDecoration: "underline" }}
              >
                Privacy Policy
              </a>
            </Typography>
          }
        />
        {errors.agreeToTerms && (
          <FormHelperText error>{errors.agreeToTerms}</FormHelperText>
        )}
      </Grid>
    </Grid>
  );
};

export default ClientInfo;
