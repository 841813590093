import { memo } from "react";
import PropTypes from "prop-types";
import styles from "./WhatIssuesDoWeFaceNowadays.module.css";

const WhatIssuesDoWeFaceNowadays = memo(({ className = "" }) => {
  return (
    <section className={[styles.section, className].join(" ")}>
      <div className={styles.textTitlePage}>
        <h1 className={styles.textTitlePage1}>
          What issues do we face nowadays?
        </h1>
      </div>
      <div className={styles.tiles}>
        <div className={styles.left}>
          <div className={styles.tile}>
            <div className={styles.text}>
              <div className={styles.text1}>
                Multiple loyalty apps crowding our phones
              </div>
            </div>
            <div className={styles.text}>
              <div className={styles.text1}>
                Non tailored messaging and usually blanket offers
              </div>
            </div>
            <div className={styles.text}>
              <div className={styles.text1}>
                no real engagement and being offered things not relevant to me
              </div>
            </div>
            <div className={styles.text}>
              <div className={styles.text1}>
                Slow and confusing customer experience
              </div>
            </div>
          </div>
          <div className={styles.tile1}>
            <div className={styles.circle}>
              <div className={styles.text8}>
                <h2 className={styles.leftGraphicValue}>USER</h2>
              </div>
            </div>
            <img
              className={styles.graphicIcon}
              
              alt=""
              src="/graphic-1.svg"
            />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.tile}>
            <div className={styles.text9}>
              <div className={styles.text1}>
                Expensive to afford full blown loyalty builds
              </div>
            </div>
            <div className={styles.text9}>
              <div className={styles.text1}>
                Maintaining a loyalty program itself - automation?
              </div>
            </div>
            <div className={styles.text9}>
              <div className={styles.text1}>
                Disrupts daily operations of a retailer
              </div>
            </div>
            <div className={styles.text9}>
              <div className={styles.text1}>
                No real actionable insights to action on
              </div>
            </div>
          </div>
          <div className={styles.tile1}>
            <div className={styles.circle1}>
              <div className={styles.text8}>
                <h2 className={styles.leftGraphicValue}>BRAND</h2>
              </div>
            </div>
            <img
              className={styles.graphicIcon1}
              
              alt=""
              src="/graphic-2.svg"
            />
          </div>
        </div>
      </div>
    </section>
  );
});

WhatIssuesDoWeFaceNowadays.propTypes = {
  className: PropTypes.string,
};

export default WhatIssuesDoWeFaceNowadays;
