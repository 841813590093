import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import { CSSTransition } from "react-transition-group";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ToggleColorMode from "../ToggleColorMode";
import CssBaseline from "@mui/material/CssBaseline";

export default function Header() {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [menuIcon, setMenuIcon] = useState("☰");

  const navRef = useRef(null);
  const location = useLocation(); // Get the current route

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 800px)");

    // Set initial state based on media query
    setIsSmallScreen(mediaQuery.matches);

    // Add event listener for media query changes
    mediaQuery.addEventListener("change", handleMediaQueryChange);

    // Cleanup function to remove the event listener
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  useEffect(() => {
    // Close the menu when the location changes
    setNavVisibility(false);
    setMenuIcon("☰");
  }, [location]);

  const handleMediaQueryChange = (e) => {
    setIsSmallScreen(e.matches);
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
    setMenuIcon(isNavVisible ? "☰" : "✕");
  };

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/JoinSaku"); // Redirect to the sign-up page
  };

  const [mode, setMode] = useState("light");
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  return (
    <header
      className={`saku-header ${location.pathname === "/" ? "home-page" : ""}`}
    >
      {" "}
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <Link to="/Home">
          <img
            src={require("../../assets/images/logo/Full Colour@3x.webp")}
            className="saku-logo"
            alt="logo"
          />
        </Link>
        <CSSTransition
          nodeRef={navRef} // Use nodeRef instead of ref for CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={350}
          classNames="saku-nav-animation"
          unmountOnExit
        >
          <nav ref={navRef} className="saku-nav">
            <Link
              to="/Home"
              className={
                location.pathname === "/Home" || location.pathname === "/"
                  ? "active-link"
                  : ""
              }
            >
              Loyalty?
            </Link>
            <Link
              to="/Solution"
              className={location.pathname === "/Solution" ? "active-link" : ""}
            >
              Let Us Help
            </Link>
            <Link
              to="/AboutUs"
              className={location.pathname === "/AboutUs" ? "active-link" : ""}
            >
              About Us
            </Link>
            {/* <Link
              to="/Price"
              className={location.pathname === "/Price" ? "active-link" : ""}
            >
              Pricing
            </Link> */}
            <Link
              to="/FAQ"
              className={location.pathname === "/FAQ" ? "active-link" : ""}
            >
              FAQ
            </Link>
            <Link
              to="/Blog"
              className={location.pathname === "/Blog" ? "active-link" : ""}
            >
              Blog
            </Link>
            <button
              className={`JoinSakuButton ${
                location.pathname === "/JoinSaku" ? "active-link" : ""
              }`}
              onClick={handleButtonClick}
            >
              Join Saku
            </button>
            {/* <ToggleColorMode
              className="ToggleThemeColorButton"
              mode={mode}
              toggleColorMode={toggleColorMode}
            /> */}
          </nav>
        </CSSTransition>
        <button
          className="saku-menu-joinsakubutton"
          onClick={handleButtonClick}
        >
          Join Saku
        </button>
        <button onClick={toggleNav} className="saku-menu">
          {menuIcon}
        </button>
      </ThemeProvider>
    </header>
  );
}
