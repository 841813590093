import { memo } from "react";
import PropTypes from "prop-types";
import styles from "./OurSolution.module.css";
import { Link } from 'react-router-dom';

const OurSolution = memo(({ className = "" }) => {
  return (
    <section className={[styles.section, className].join(" ")}>
      <div className={styles.textTitlePage}>
        <h1 className={styles.textTitlePage1}>Our solution</h1>
      </div>
      <div className={styles.tiles}>
        <div className={styles.circle}>
          <div className={styles.textTitleHero}>
            <b className={styles.textTitleHero1}>1</b>
          </div>
          <div className={styles.text}>
            <h2 className={styles.text1}>Accessible Loyalty Program For All</h2>
          </div>
          <div className={styles.text2}>
            <div className={styles.text3}>
              <ul className={styles.makingItAccessibleForBrand}>
                <li className={styles.makingItAccessible}>
                  Making it accessible for brands to participate with minimal
                  investment
                </li>
                <li>Flexible for both users and brands to use</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.circle1}>
          <div className={styles.textTitleHero}>
            <b className={styles.textTitleHero3}>2</b>
          </div>
          <div className={styles.text}>
            <h2 className={styles.text1}>
              <p className={styles.allInOne}>All-In-One</p>
              <p className={styles.allInOne}>Loyalty Wallet</p>
            </h2>
          </div>
          <div className={styles.text2}>
            <div className={styles.text3}>
              <ul className={styles.makingItAccessibleForBrand}>
                <li className={styles.makingItAccessible}>
                  One app for all loyalty cards
                </li>
                <li className={styles.makingItAccessible}>
                  Put your favorites first and nearby locations to toggle
                </li>
                <li>Be in the know of latest deals nearest to you</li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.circle2}>
          <div className={styles.textTitleHero}>
            <b className={styles.textTitleHero5}>3</b>
          </div>
          <div className={styles.text}>
            <h2 className={styles.text9}>Engagement</h2>
          </div>
          <div className={styles.text2}>
            <div className={styles.text3}>
              <ul className={styles.makingItAccessibleForBrand}>
                <li className={styles.makingItAccessible}>
                  Using strong algorithms and predictive behavioral analysis, be
                  able to segment and target users at their right channels
                </li>
                <li>
                  Get deals that you really want from brands you really want
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Link to="/Solution" className={styles.button}>
        <div className={styles.button1}>Come Explore</div>
        <img className={styles.arrowRightIcon} alt="" src="/arrow-right.svg" />
      </Link>
      <img
        className={styles.graphicIcon}
        
        alt=""
        src="/graphic-5@2x.webp"
      />
    </section>
  );
});

OurSolution.propTypes = {
  className: PropTypes.string,
};

export default OurSolution;
