import * as React from "react";
import { Stack, Typography, Box, Paper, Button } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import PaymentIcon from "@mui/icons-material/Payment";
import EmailIcon from "@mui/icons-material/Email";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function PaymentFail() {
  const query = useQuery();
  const paymentUrl = query.get("PaymentUrl");

  const handleRetryPayment = () => {
    if (paymentUrl) {
      window.location.href = paymentUrl;
    }
  };

  const handleContactSupport = () => {
    const email = "support@saku.my";
    window.location.href = `mailto:${email}`;
  };

  return (
    <Stack
      spacing={4}
      sx={{ p: 4, textAlign: "center", maxWidth: 800, mx: "auto" }}
    >
      <Paper
        elevation={4}
        sx={{ p: 4, backgroundColor: "#ffe8e8", borderRadius: 3 }}
      >
        <Stack spacing={3} alignItems="center">
          <ErrorIcon fontSize="large" color="error" sx={{ fontSize: 80 }} />
          <Typography variant="h4" color="textPrimary">
            Oops! Something went wrong with your payment.
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            We’re sorry, but it looks like your payment didn’t go through.
            Please double-check your payment details and try again.
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            If you continue to experience issues, feel free to contact our
            support team for assistance. We’re here to help!
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              onClick={handleRetryPayment}
              startIcon={<PaymentIcon />}
              variant="contained"
              color="success"
              sx={{ textTransform: "none" }}
              disabled={!paymentUrl}
            >
              Retry Payment
            </Button>
            <Button
              onClick={handleContactSupport}
              startIcon={<EmailIcon />}
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
            >
              Contact Support
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
}
