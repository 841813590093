import { memo } from "react";
import WhatIsLoyalty from "./WhatIsLoyalty";
import HowMuchWillItCostMe from "./HowMuchWillItCostMe";
import WhatIssuesDoWeFaceNowadays from "./WhatIssuesDoWeFaceNowadays";
import SoHowCanItBeEasier from "./SoHowCanItBeEasier";
import PairingAnExcellentCustomer from "./PairingAnExcellentCustomer";
import OurSolution from "./OurSolution";
import PropTypes from "prop-types";
import styles from "./SakuHome.module.css";

const SakuHome = memo(({ className = "" }) => {
  return (
    <div className={[styles.sakuhome, className].join(" ")}>
      <section className={styles.heroActions}>
        <div className={styles.textContentTitle}>
          <h1 className={styles.title}>
            Where unexpected rewards come together
          </h1>
        </div>
        <img
          className={styles.sakuGirlIcon}
          alt=""
          src="/saku-girl.webp"
        />
      </section>
      <WhatIsLoyalty />
      <HowMuchWillItCostMe />
      <WhatIssuesDoWeFaceNowadays />
      <SoHowCanItBeEasier />
      <PairingAnExcellentCustomer />
      <OurSolution />
    </div>
  );
});

SakuHome.propTypes = {
  className: PropTypes.string,
};

export default SakuHome;
