import { memo } from "react";
import PropTypes from "prop-types";
import styles from "./PairingAnExcellentCustomer.module.css";

const PairingAnExcellentCustomer = memo(({ className = "" }) => {
  return (
    <section className={[styles.section, className].join(" ")}>
      <div className={styles.tiles}>
        <img className={styles.giftBox1Icon} alt="" src="/002giftbox-1.svg" />
        <img className={styles.plusIcon} alt="" src="/plus.svg" />
        <img className={styles.giftBox1Icon} alt="" src="/001family-1.svg" />
      </div>
      <div className={styles.textTitlePage}>
        <h2 className={styles.textTitlePageContainer}>
          <p
            className={styles.pairingAnExcellentCustomer}
          >{`Pairing an excellent customer loyalty program with great customer experience `}</p>
          <p className={styles.pairingAnExcellentCustomer}>
            is a way to increase sales and generate repeat business!
          </p>
        </h2>
      </div>
      <div className={styles.graphicWrapper}>
        <img
          className={styles.graphicIcon}
          
          alt=""
          src="/graphic-4@2x.webp"
        />
      </div>
    </section>
  );
});

PairingAnExcellentCustomer.propTypes = {
  className: PropTypes.string,
};

export default PairingAnExcellentCustomer;
