export const faqData = {
  user: [
    {
      question: "How do I become a member of <strong>Saku</strong>?",
      answer:
        "It’s easy! You can sign up via the mobile app, just download <strong>Saku</strong> App from Google Play store on Android or Apple App store on IOS. Link : <a href='https://app.saku.my/'>https://app.saku.my/</a> .After successful signed-up, search for your desired <strong>Saku</strong> reward Partners and tap to join membership. Start enjoying your membership.",
    },
    {
      question: "Do I have to pay to use <strong>Saku</strong> app?",
      answer:
        "No, <strong>Saku</strong> app is absolutely free. However, certain reward Partners might impose a membership fee for joining their loyalty program.",
    },
    {
      question: "Can I join more than one reward Partners’ membership in <strong>Saku</strong>?",
      answer:
        "Absolutely. You may wish to benefit from the different privileges offered through our range of reward Partners and that’s fine. The more the merrier!",
    },
    {
      question: "I can’t receive my OTP during registration, what can I do?",
      answer:
        "If you have problem receiving OTP from <strong>Saku</strong> app, you can request it from any of our participating reward Partners.",
    },
    {
      question:
        "Is my registered information shared to all reward Partners in <strong>Saku</strong>?",
      answer:
        "No, your registered info shall be shared to reward Partners that you chose to join.",
    },
    {
      question: "Is my reward shared across different reward Partners?",
      answer:
        "Unfortunately at this point, no. But if you think you would like this feature embedded, email us at <a href='mailto:feedback@saku.my'>feedback@saku.my</a>",
    },
    {
      question: "I did not receive my rewards, who should I refer to?",
      answer:
        "Reward Partners are responsible for the rewards that they offer. In case you have problem on receiving rewards, please contact the respective reward Partners to seek assistance.",
    },
    {
      question: "What is Redemption PIN and what is it used for?",
      answer:
        "You’ll be asked to set your Redemption PIN during the first time login to <strong>Saku</strong> app. The Redemption PIN is a 6-digit number required to authorize a redemption using the reward points.",
    },
    {
      question: "What shall I do if I forgot or lost my Redemption PIN?",
      answer:
        "To reset your Redemption PIN, go to > Account > click on Edit Profile > Change Redemption PIN > click Forgot Redemption PIN. A random Redemption PIN will be sent to your registered smartphone mobile number.",
    },
    {
      question: "Why is my account in <strong>Saku</strong> blocked?",
      answer:
        "The safety of your <strong>Saku</strong> account is our priority. Your account will be blocked if, in any circumstances, violates the <strong>Saku</strong> terms of usage. For assistance to unblock your <strong>Saku</strong> account, please click > CONTACT US  or email us at <a href='mailto:feedback@saku.my'>feedback@saku.my</a>",
    },
    {
      question: "How do I redeem for products with my points?",
      answer:
        "All reward points earned and accumulated can be redeemed by showing redemption QR codes at <strong>Saku</strong> participating reward Partners’ stores or via <strong>Saku</strong> in-app Online Catalogue.",
    },
    {
      question: "How do I cancel/withdraw my joined membership?",
      answer:
        "Please contact the respective reward Partners for any membership cancellation/withdrawal.",
    },
    {
      question: "How do I remove my <strong>Saku</strong> account?",
      answer:
        "You may go to App > Settings > Account Deletion to delete your <strong>Saku</strong> account. Upon deletion, all your membership shall be terminated and your registered info shall be removed from <strong>Saku</strong> and all joined membership.",
    },
    {
      question: "Where can I send my feedbacks about <strong>Saku</strong>?",
      answer:
        "You can email us at <a href='mailto:feedback@saku.my'>feedback@saku.my</a> Our Customer Service Team will get back to you in 48 hours.",
    },
    {
      question:
        "I can’t remember the mobile number that I used to register in <strong>Saku</strong>. What do I do?",
      answer:
        "Please email us at <a href='mailto:feedback@saku.my'>feedback@saku.my</a> with details of your registration information, i.e. Name, Email Address and Date-of-Birth, for assistance.",
    },
    {
      question:
        "Do I automatically join/become a member of all reward Partners in <strong>Saku</strong>?",
      answer:
        "No. You are required to select and join the membership of your desired reward Partner(s).",
    },
    {
      question:
        "How far back can I view my transactions and/or points history in <strong>Saku</strong>?",
      answer:
        "You may view transactions history of up to the latest 100 transactions from <strong>Saku</strong>.",
    },
    {
      question:
        "I just noticed that my points and/or vouchers have expired, can I request for reinstatement?",
      answer:
        "Unfortunately, we can’t reinstate expired points and/or vouchers. We strongly advise you to utilize <strong>Saku</strong> to regularly keep track of your points and/or vouchers validity.",
    },
    {
      question:
        "Can I cancel the redemption or exchange for another item after redemption is made?",
      answer:
        "We are sorry but this is not possible. Once reward points are redeemed or burned, the redemption cannot be cancelled, exchanged or returned.",
    },
  ],
  merchant: [
    {
      question: "How can I join <strong>Saku</strong> as a reward Partner?",
      answer:
        "You can sign up here (<a href='https://saku.my/JoinSaku'>https://saku.my/JoinSaku</a>). In just 4 simple steps, you are ready to start your loyalty program!",
    },
    {
      question: "Is there any fee required to join as a <strong>Saku</strong> Reward Partner?",
      answer:
        "Yes, you are required to pay an annual subscription fee to become a <strong>Saku</strong> Reward Partner.",
    },
    {
      question: "Do I need any device to run our loyalty program with <strong>Saku</strong>?",
      answer:
        "Yes, you will need an Android mobile device (Android 6.0 and above, with at least 2GB of RAM) to install the <strong>Saku</strong> Merchant App. This app is necessary to process loyalty transactions such as rewarding and redeeming points, invalidating vouchers, and more.",
    },
    {
      question:
        "Are there any Point-of-Sale-Materials (POSMs) when I join <strong>Saku</strong> as a reward Partner?",
      answer:
        "Yes, a serial of generic POSMs will be provided to you for your outlet display.",
    },
  ],
};
