import { memo } from "react";
import PropTypes from "prop-types";
import styles from "./OurMissionAndVision.module.css";

const OurMissionAndVision = memo(({ className = "" }) => {
  return (
    <section className={[styles.section, className].join(" ")}>
      <div className={styles.left}>
        <div className={styles.section1}>
          <img
            className={styles.giftBox1Icon}
            alt=""
            src="/002giftbox-11.svg"
          />
          <div className={styles.textTitlePage}>
            <h1 className={styles.textTitlePage1}>Our Mission</h1>
          </div>
          <div className={styles.textTitlePage2}>
            <div className={styles.textTitlePage3}>
              To craft better experiences for both consumers and brands by being
              the most; Accessible loyalty programme for all Loyalty Cards.
            </div>
          </div>
          <img
            className={styles.graphicIcon}
            
            alt=""
            src="/graphic2.svg"
          />
        </div>
      </div>
      <div className={styles.left1}>
        <div className={styles.section2}>
          <img
            className={styles.giftBox1Icon}
            alt=""
            src="/002giftbox-11.svg"
          />
          <div className={styles.textTitlePage}>
            <h1 className={styles.textTitlePage1}>Our Vision</h1>
          </div>
          <div className={styles.textTitlePage2}>
            <div className={styles.textTitlePage3}>
              To be available to all and to make; Merchant, Partner and the end
              User happy and engaged throughout their experience with Saku.
            </div>
          </div>
          <img
            className={styles.graphicIcon1}
            
            alt=""
            src="/graphic-11@2x.webp"
          />
        </div>
      </div>
    </section>
  );
});

OurMissionAndVision.propTypes = {
  className: PropTypes.string,
};

export default OurMissionAndVision;
