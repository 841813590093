import { memo, useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./ContactOurTeam.module.css";

const ContactOurTeam = memo(
  ({
    className = "",
    graphics,
    graphicsIconMinHeight,
    textWidth,
    textPadding,
    textMinWidth,
    text,
    propPadding,
    propWidth,
    propDisplay,
    propColor,
    textTitlePageTextAlign,
    buttonStart,
  }) => {
    const graphicsIconStyle = useMemo(() => {
      return {
        minHeight: graphicsIconMinHeight,
      };
    }, [graphicsIconMinHeight]);

    const textStyle = useMemo(() => {
      return {
        width: textWidth,
        padding: textPadding,
        minWidth: textMinWidth,
      };
    }, [textWidth, textPadding, textMinWidth]);

    return (
      <section className={[styles.sakucontactUs, className].join(" ")}>
        <div className={styles.section}>
          <img
            className={styles.graphicsIcon}
            
            alt=""
            src={graphics}
            style={graphicsIconStyle}
          />
          <div className={styles.text} style={textStyle}>
            <div className={styles.textTitlePage}>
              <h1 className={styles.textTitlePage1}>Contact our team</h1>
            </div>
            <div className={styles.buttonGroup}>
              <div className={styles.button}>
                <img className={styles.starIcon} alt="" src="/star.svg" />
                <div className={styles.button1}>Corporate Site</div>
                <img
                  className={styles.arrowRightIcon}
                  alt=""
                  src="/arrow-right.svg"
                />
              </div>
              <a target="_blank" href="https://us.bigin.online/org866370276/forms/sakurewards" className={styles.button2}>
                <img
                  className={styles.arrowRightIcon}
                  
                  alt=""
                  src="/mail.svg"
                />
                <div className={styles.button3}>Email Us</div>
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

ContactOurTeam.propTypes = {
  className: PropTypes.string,
  graphics: PropTypes.string,
  text: PropTypes.string,
  propPadding: PropTypes.string,
  propWidth: PropTypes.string,
  propDisplay: PropTypes.string,
  propColor: PropTypes.string,
  textTitlePageTextAlign: PropTypes.string,
  buttonStart: PropTypes.bool,

  /** Style props */
  graphicsIconMinHeight: PropTypes.any,
  textWidth: PropTypes.any,
  textPadding: PropTypes.any,
  textMinWidth: PropTypes.any,
};

export default ContactOurTeam;
