import { memo } from "react";
import PropTypes from "prop-types";
import styles from "./HowMuchWillItCostMe.module.css";

const HowMuchWillItCostMe = memo(({ className = "" }) => {
  return (
    <section className={[styles.section, className].join(" ")}>
      <div className={styles.textTitlePage}>
        <h1 className={styles.textTitlePage1}>How much will it cost me?</h1>
      </div>
      <div className={styles.tiles}>
        <div className={styles.tile}>
          <div className={styles.text}>
            <div className={styles.text1}>
              <div className={styles.text2}>
                <p className={styles.x}>
                  <b className={styles.b}>5x</b>
                </p>
                <p className={styles.costs5Times}>
                  Costs 5 times more to obtain a new customer than it does to
                  keep an existing customers
                </p>
              </div>
            </div>
            <div className={styles.text3}>
              <div className={styles.text2}>
                <p className={styles.x}>
                  <b className={styles.b}>{`5% > 100%`}</b>
                </p>
                <p className={styles.costs5Times}>
                  Some suggest that a business that retains 5% of its customers
                  can increase its profits by 100%
                </p>
              </div>
            </div>
          </div>
          <div className={styles.text5}>
            <div className={styles.text6}>
              Major reason for this is that serving a loyal customer costs less
              than attracting a new customer. Loyal customers know the
              organisation well, do not need much information and often
              recommend the organisation to others.
            </div>
          </div>
        </div>
        <img
          className={styles.graphicIcon1}
          
          alt=""
          src="/graphic1.svg"
        />
      </div>
    </section>
  );
});

HowMuchWillItCostMe.propTypes = {
  className: PropTypes.string,
};

export default HowMuchWillItCostMe;
