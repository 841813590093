import { memo } from "react";
import ToBeTheSakuForLoyalty from "./ToBeTheSakuForLoyalty";
import MemberBrand from "./MemberBrand";
import GrowYourCustomersBaseEngageWithThem from "./GrowYourCustomersBaseEngageWithThem";
import PropTypes from "prop-types";
import styles from "./SakuSolution.module.css";
import { Link } from 'react-router-dom';

const SakuSolution = memo(({ className = "" }) => {
  return (
    <div className={[styles.sakusolution, className].join(" ")}>
      <section className={styles.section}>
        <ToBeTheSakuForLoyalty />
      </section>
      <div className={styles.section1}>
        <div className={styles.tile}>
          <div className={styles.text}>
            <div className={styles.textTitlePageParent}>
              <div className={styles.textTitlePage}>
                <div className={styles.textTitlePage1}>{`To be the `}</div>
              </div>
              <img
                className={styles.sakuLogoIcon}
                alt=""
                src="/saku-logo.svg"
              />
              <div className={styles.textTitlePage2}>
                <b className={styles.textTitlePage3}>for loyalty</b>
              </div>
            </div>
            <div className={styles.whichBasicallyMeansContainer}>
              <p
                className={styles.whichBasicallyMeans}
              >{`Which basically means a loyalty wallet that is for `}</p>
              <p className={styles.whichBasicallyMeans}>
                everyone – merchant / partner and also the user
              </p>
            </div>
          </div>
          <div className={styles.graphics}>
            <img className={styles.graphicsIcon} alt="" src="/graphics-1.svg" />
          </div>
        </div>
      </div>
      <section className={styles.section2}>
        <div className={styles.tiles}>
          <div className={styles.tile1}>
            <img
              className={styles.coinIcon}
              
              alt=""
              src="/coin.svg"
            />
            <img
              className={styles.coinIcon}
              
              alt=""
              src="/coin-1.svg"
            />
            <img className={styles.coinIcon} alt="" src="/coin-2.svg" />
            <img className={styles.coinIcon} alt="" src="/coin-1.svg" />
            <img
              className={styles.coinIcon}
              
              alt=""
              src="/coin-4.svg"
            />
            <img
              className={styles.coinIcon}
              
              alt=""
              src="/coin.svg"
            />
            <div className={styles.graphicF}>
              <div className={styles.coinParent}>
                <img
                  className={styles.coinIcon6}
                  
                  alt=""
                  src="/coin-6.svg"
                />
                <img
                  className={styles.coinIcon6}
                  
                  alt=""
                  src="/coin-7.svg"
                />
              </div>
              <img
                className={styles.sakuIcon1}
                
                alt=""
                src="/saku-icon-1.svg"
              />
            </div>
            <img className={styles.coinIcon} alt="" src="/coin-8.svg" />
            <img
              className={styles.coinIcon}
              
              alt=""
              src="/coin-1.svg"
            />
            <img
              className={styles.coinIcon}
              
              alt=""
              src="/coin.svg"
            />
            <img className={styles.coinIcon} alt="" src="/coin-1.svg" />
            <img className={styles.coinIcon} alt="" src="/coin-2.svg" />
            <img
              className={styles.coinIcon}
              
              alt=""
              src="/coin-8.svg"
            />
          </div>
          <div className={styles.text1}>
            <div className={styles.textTitlePage4}>
              <h1 className={styles.textTitlePage5}>What is Saku?</h1>
            </div>
            <div className={styles.text2}>
              <div className={styles.paragraph}>
                <p className={styles.noun}>NOUN</p>
                <p className={styles.pocket}>pocket</p>
                <p className={styles.pocket}>
                  small bag-like part in or on a garment
                </p>
                <p className={styles.pocket}>one’s resources of money</p>
                <p className={styles.pocket}>pouch-like compartment</p>
                <p className={styles.noun}>&nbsp;</p>
                <p className={styles.noun}>&nbsp;</p>
                <p className={styles.noun}>ADJECTIVE</p>
                <p className={styles.pocket}>
                  suitable for carrying in one’s pocket
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MemberBrand />
      <GrowYourCustomersBaseEngageWithThem />
      <section className={styles.sakucontactUs}>
        <div className={styles.section3}>
          <div className={styles.text3}>
            <div className={styles.textTitlePage}>
              <h1 className={styles.textTitlePage7}>Join Saku Now</h1>
            </div>
            <div className={styles.description}>
              <div className={styles.everythingEssentialForContainer}>
                <p className={styles.everythingEssentialFor}>
                  Everything essential for a successful loyalty and membership
                  program.
                </p>
                <p className={styles.everythingEssentialFor}>&nbsp;</p>
                <ul className={styles.allYouNeedToGetStarted}>
                  <li className={styles.forBrandsWithFewerResource}>
                    <span className={styles.allYouNeed}>
                      All you need to get started. 
                    </span>
                  </li>
                  <li className={styles.forBrandsWithFewerResource}>
                    <span className={styles.allYouNeed}>
                      For brands with fewer resources or minimum maintenance. 
                    </span>
                  </li>
                  <li className={styles.forBrandsWithFewerResource}>
                    <span className={styles.allYouNeed}>
                      Rewards automation and automated messages.
                      ​
                    </span>
                  </li>
                </ul>
                <p className={styles.everythingEssentialFor}>&nbsp;</p>
                <p className={styles.everythingEssentialFor}>From</p>
                <p className={styles.whichBasicallyMeans}>
                  <b className={styles.rm170}>RM170</b>
                  <span className={styles.allYouNeed}>{`/month `}</span>
                </p>
                <p className={styles.everythingEssentialFor}>(billed yearly)</p>
              </div>
              <div className={styles.featuresLoyaltyPointsContainer}>
                <p className={styles.whichBasicallyMeans}>
                  <b>Features</b>
                </p>
                <ul className={styles.loyaltyPointsAndRewardsGi}>
                  <li className={styles.forBrandsWithFewerResource}>
                    <span>Loyalty Points and Rewards </span>
                  </li>
                  <li className={styles.forBrandsWithFewerResource}>
                    <span>Gift card capabilities</span>
                  </li>
                  <li className={styles.forBrandsWithFewerResource}>
                    <span>Merchant Portal </span>
                  </li>
                  <li className={styles.forBrandsWithFewerResource}>
                    <span>Campaign Messaging </span>
                  </li>
                  <li className={styles.forBrandsWithFewerResource}>
                    <span>Member Referral </span>
                  </li>
                  <li className={styles.forBrandsWithFewerResource}>
                    <span>Journey Stamps</span>
                  </li>
                </ul>
              </div>
            </div>
            <Link to="/JoinSaku" className={styles.button}>
              <div className={styles.button1}>Join Saku</div>
              <img
                className={styles.arrowRightIcon}
                alt=""
                src="/arrow-right.svg"
              />
            </Link>
          </div>
          <img
            className={styles.graphicIcon}
            
            alt=""
            src="/graphic-31@4x.webp"
          />
        </div>
      </section>
    </div>
  );
});

SakuSolution.propTypes = {
  className: PropTypes.string,
};

export default SakuSolution;
