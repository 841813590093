import * as React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

import Info from "./Info";

function InfoMobile({
  totalOutlet,
  handleTotalPriceChange,
  selectedClientPackage,
}) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: "auto", px: 3, pb: 3, pt: 8 }} role="presentation">
      <IconButton
        onClick={toggleDrawer(false)}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>
      <Info
        totalOutlet={totalOutlet}
        onTotalPriceChange={handleTotalPriceChange}
        selectedClientPackage={selectedClientPackage}
      />
    </Box>
  );

  return (
    <div>
      <Button
        variant="text"
        endIcon={<ExpandMoreRoundedIcon />}
        onClick={toggleDrawer(true)}
        style={{ fontWeight: "bold" }}
      >
        View details
      </Button>
      <Drawer open={open} anchor="top" onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}

InfoMobile.propTypes = {
  totalOutlet: PropTypes.number.isRequired,
  handleTotalPriceChange: PropTypes.func.isRequired,
  selectedClientPackage: PropTypes.object, // Object containing the selected package details
};

export default InfoMobile;
