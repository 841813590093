import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Description } from "@mui/icons-material";

const DEFAULT_PACKAGE_PRICE = 0.0; // Default package price per year
const DEFAULT_OUTLET_PRICE = 0.0; // Price per additional outlet per year

function Info({ totalOutlet, onTotalPriceChange, selectedClientPackage }) {
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [totalOutletPrice, setTotalOutletPrice] = useState(0);
  const [totalSST, setTotalSST] = useState(0);

  useEffect(() => {
    // Calculate the package price (falling back to default if none is selected)
    const packagePrice = Number(
      selectedClientPackage?.packagePrice || DEFAULT_PACKAGE_PRICE
    );

    // Calculate the price for additional outlets (no charge for the first outlet)
    const outletPrice =
      totalOutlet > 1
        ? (totalOutlet - 1) *
          Number(selectedClientPackage?.outletPrice || DEFAULT_OUTLET_PRICE)
        : 0.0;

    // Calculate the total price
    const calculatedPrice = packagePrice + outletPrice;
    const sst = calculatedPrice * 0.08;
    const totalPriceIncldeSST = calculatedPrice + sst;

    // Update state with the calculated values
    setTotalPrice(totalPriceIncldeSST);
    setTotalOutletPrice(outletPrice);
    setTotalSST(sst);
    // Notify parent component of the price change
    onTotalPriceChange(totalPriceIncldeSST);
  }, [totalOutlet, selectedClientPackage, onTotalPriceChange]);

  // Define the products and their prices
  const products = [
    {
      name: "Full Access to Saku Loyalty Platform",
      description: "(SakuBiz App & Merchant Portal)",
      price: Number(
        selectedClientPackage?.packagePrice || DEFAULT_PACKAGE_PRICE
      ),
    },
    {
      name: `${totalOutlet} Outlet(s) (By Device)`,
      price: totalOutletPrice,
    },
    {
      name: "Total SST (8%)",
      price: totalSST,
    },
    {
      name: "Loyalty Points, Credit, Voucher & Referral",
      price: 0, // Currently free
    },
    {
      name: "Member & Transaction Reports",
      price: 0, // Currently free
    },
    {
      name: "1,000 Messaging Credits",
      description: "(SMS & Email)",
      price: 0, // Currently free
    },
  ];

  return (
    <React.Fragment>
      {/* Display the total price */}
      <Typography
        variant="h5"
        color="var(--tertiary-color-3)"
        style={{ fontWeight: "bold" }}
      >
        Total : RM {totalPrice.toFixed(2)}
      </Typography>

      {/* Display the list of products and their prices */}
      <List disablePadding>
        {products.map((product) => (
          <ListItem
            key={product.name}
            sx={{ py: 1, px: 0 }}
            alignItems="flex-start"
          >
            <ListItemText
              sx={{ mr: 2 }}
              primary={product.name}
              secondary={product.description}
            />
            <Typography variant="body1" fontWeight="medium" sx={{ mt: 0.75 }}>
              {product.price > 0 ? (
                `${product.price.toFixed(2)}`
              ) : (
                <strong>FREE</strong>
              )}
            </Typography>
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  );
}

Info.propTypes = {
  totalOutlet: PropTypes.number.isRequired,
  onTotalPriceChange: PropTypes.func.isRequired,
  selectedClientPackage: PropTypes.object, // Object containing the selected package details
};

export default Info;
