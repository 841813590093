import React from "react";
import Layout from "./components/Layout";
import ScrollToTop from "./components/ScrollToTop";
import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {

  return (
    <>
      <ScrollToTop />
      <Layout>
        <AnimatedRoutes />
      </Layout>
    </>
  );
}

export default App;
