import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const MerchantTerms = () => {
  return (
    <Container
      sx={{
        backgroundColor: "#f7f9fc", // Light background to make it stand out
        padding: "16px",
        borderRadius: "8px", // Rounded corners for a modern feel
        mb: 3,
        borderLeft: "6px solid var(--primary-color-2)", // Accent color for definition box
        borderRight: "6px solid var(--primary-color-2)", // Accent color for definition box
      }}
    >
      <Box>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          SAKUBiz Terms of Use (PARTNERS)
        </Typography>
        <Typography variant="body1" paragraph>
          By downloading, installing, and/or using the SAKUBiz Application, You
          agree that You have read, understood and accepted and agreed to these
          Terms and Condition (“Terms of Use”).
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms of Use constitute a legal agreement between You and
          Propagate Technologies Sdn Bhd (“SAKUbiz” - including affiliate) and
          apply to Your visit to and use of Our website at{" "}
          <a href="https://saku.my">https://saku.my</a> or other sites which We
          developed officially to support the Service ecosystem.
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          PLEASE REVIEW OUR TERMS OF USE AND PRIVACY POLICY CAREFULLY BEFORE
          DOWNLOADING SAKUBiz APPLICATION OR USING OUR SERVICE FOR THE FIRST
          TIME.
        </Typography>
        <Typography variant="body1" paragraph>
          The Services available to You on the SAKUBiz Application are so
          diverse that We can include or enforce additional Terms of Use for the
          purpose of certain Services, in which the additional terms are part of
          Our Services which are bound and inherent to these Terms of Use.
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to modify, vary and change the Terms of Use or
          Privacy Policy relating to the Services at any time as it deems fit so
          that You are required from time to time read Our Terms of Use and
          Privacy Policy in the SAKUBiz Application or Our website.
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms of Use apply to You nor for Your use of the Service by or
          through Your account wherever you may be, whether in the states and
          federal territories of Malaysia or in any other countreries.
        </Typography>

        {/* Definition Section */}
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          FOR THE AVOIDANCE OF DOUBT, WE ARE A SOFTWARE AS A SERVICE (SAAS)
          COMPANY WHICH PROVIDES PLATFORM FOR EACH SERVICES WE PROVIDE AND NOT A
          PUBLISHER OF ELECTRONIC MONEY.
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          1) DEFINITION
        </Typography>
        <Typography variant="body1" paragraph>
          In this Terms of Use, unless expressly provided otherwise, the
          following expressions have the following meanings:
        </Typography>
        <List sx={{ paddingLeft: 2 }}>
          <ListItem disableGutters>
            <ListItemText
              primary="“You” means the party registered as a SAKUBiz Application user to use
          the Services provided in the SAKUBiz Application."
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText
              primary="“Affiliate” means, in relation to a party, any entity that controls,
          is under the control of, or is under common control with or the legal
          power to direct or cause the direction of the general management and
          policies of that party."
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText
              primary="“SAKUBiz Application” is a mobile app with the name SAKUBiz for
          Loyalty Memberships, and its derivative which can be downloaded via
          playstore for Android users as well as through via app store for iOS
          users that We develop to support Our Services provided."
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText
              primary="“Personal Information” means information regarding Yourself which can
          be privately identified and collected through the SAKUBiz Application,
          such as name, address, date of birth and occupation (if You are a
          person), corporate data and identity document (if You are not a
          person), phone number, email address and other information which may
          identify You as an user of SAKUBiz Application."
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText
              primary="“We/Our/Us” means SAKUBiz (including affiliate) a company established
          under the laws of the states and federal of Malaysia."
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText
              primary="“Terms of Use” means terms and condition or standard operational
          procedures or other terms in connection with SAKUBiz Application or
          website developed by Us."
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="“Service” means various services offered by SAKUBiz Application or website. " />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText
              primary="“Force Majeure” means any cause which is not reasonably within the
          control of the person affected including, without limitation, an act
          of God, an industrial dispute, war, blockade, disturbance, lightning,
          fire, earthquake, storm, flood, explosion or meteor, restraint of a
          statutory authority, expropriation, prohibition, unavailability or
          delay in availability of equipment or transport."
            />
          </ListItem>
          <ListItem disableGutters>
            <Typography
              variant="body1"
              sx={{ lineHeight: 1.6 }}
              dangerouslySetInnerHTML={{
                __html: `“Customer Service” is the customer service center We provide for You
          and can be reached by email <a href='mailto:info@saku.my'>info@saku.my</a> and can be used when You have
          problems in the use of SAKUBiz Application or when you use Our
          Service."`,
              }}
            />
          </ListItem>
          <ListItem disableGutters>
            <Typography
              variant="body1"
              sx={{ lineHeight: 1.6 }}
              dangerouslySetInnerHTML={{
                __html: `“Website” means Our website at <a href='https://saku.my' target='_blank' rel='noopener noreferrer'>https://saku.my</a> or other sites which We developed officially to support the Service ecosystem.`,
              }}
            />
          </ListItem>
        </List>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          2) TERMS FOR USING THE APPLICATION
        </Typography>
        <Typography variant="body1" paragraph>
          By using SAKUbiz and the Services in any way you are agreeing to
          comply with these Terms and Conditions and SAKUbiz Privacy Policy.
          SAKUbiz is provided to you upon agreement and sign-up of the SAKUbiz
          program. By using SAKUbiz you also understand and agree that SAKUbiz
          is not responsible for any content posted in SAKUbiz by participating
          businesses, and you, nonetheless, may be exposed to such materials.
          You agree to use SAKUbiz at your own risk. You should periodically
          visit this page to review the current Terms of Use so you are aware of
          any revision to which you are bound.
        </Typography>
        <List sx={{ listStyleType: "lower-alpha", pl: 4 }}>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You are to provide accurate information when using SAKUbiz including, but not limited to, information required to register with SAKUbiz." />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You must accurately represent yourself when using SAKUbiz; other people, automated computer programs (“bots”), deceased individuals, or anything of the sort may not use SAKUbiz and SAKUbiz’s Services on your behalf. " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You are not to access and use data not intended for you, such as logging into an account you are not authorized to access. " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You are responsible for maintaining the security of your account and password.  SAKUbiz will not be liable for any loss or damage from your failure to comply with this security obligation. " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You are responsible for all Content posted and activity that occurs under your account. " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You may not use SAKUbiz for any illegal, fraudulent, false, speculative, or unauthorized purposes not permitted by this Agreement. You must not violate any Federal or State laws when using SAKUbiz. " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You must not act maliciously or illegally against any participating businesses using SAKUbiz. " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You represent and warrant that You are an individual legally entitled to enter into a binding agreement under the laws of the states and federal of Malaysia, in particular the Terms of Use, to use the SAKUBiz Application. Otherwise, We will be entitled by law to cancel any agreement entered into with You. You further represent and warrant that You have the right, authority and capacity to use the Service and to abide by the Terms of Use. " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="We collect and process your Personal Information, such as Your name, address, date of birth, and occupation (if You are a person), corporate identity documents (if You are not a person), mobile number, email address and other information who may be able to identify You as an User of SAKUBiz Application. You must provide accurate and complete information, keep the information up to date and agree to provide Us whatever proof of identity We may reasonably ask for. " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You can only use the SAKUBiz Application once You have signed up with it. Upon successfully signing up, the SAKUBiz Application will provide You with a personal account that can be accessed with a login and password.  " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="Only You may use Your own account and You undertake not to authorize others to use Your identity or account. You may not assign or otherwise transfer Your account to any other parties. You must keep the security and confidentiality of Your account and any identification We provide to You. " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You undertake that You will use the Application only for its intended use to obtain the Service. You may not abuse or use the SAKUBiz Application for fraudulent purposes or to cause any inconvenience to others. " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You understand and agree that Your use of the SAKUBiz Application will also be subject to Our Privacy Policy as may be amended from time to time. By using the SAKUBiz Application, You also give Your consent as required under our Privacy Policy. " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You are responsible for maintaining the security of your account and password.  SAKUbiz will not be liable for any loss or damage from your failure to comply with this security obligation. " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You are responsible for all Content posted and activity that occurs under your account. " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You must not act maliciously or illegally against any participating businesses using SAKUbiz. " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="Reward Partner, at its own cost and time, is responsible for the designs, contents, descriptions and posting and/or uploading the materials into the Promotion, Push Notification and eVoucher modules. Any rewards, incentives, deals, vouchers, electronic vouchers or promotions set by Reward Partner in SAKUbiz must be fulfilled if properly redeemed by a SAKUbiz user or member. Any such SAKUbiz related fulfillment shall be in compliance with all applicable laws and regulations; and the terms of this Terms and Conditions, and the terms of the Services. " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You shall be solely responsible and liable for the consequences of your posting in SAKUbiz. You shall take absolute responsibility and liability of these postings and that SAKUbiz takes no responsibility and assumes no liability for any posting by you. " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You agree to indemnify and hold harmless SAKUbiz and its officers, directors, employees and agents, from and against any claims, suits, proceedings, disputes, demands, liabilities, damages, losses, cost and expenses, including, without limitation, reasonable legal and accounting fees (including cost of defense of claims, suits or proceedings brought by third parties), in any way related to (i) your access to or use of SAKUbiz, (ii) your posting contents, or (iii) your breach of any of these Terms. " />
          </ListItem>
        </List>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          3) PARTNER’S OBLIGATIONS
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{ fontWeight: "bold", mt: 2 }}
        >
          (a) In relation to Partner Offering
        </Typography>
        <List
          sx={{
            paddingLeft: 2,
            listStyleType: "none",
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              I. The Partner shall ensure and is obliged to procure that all
              information provided by the Partner to SAKUBiz, including but not
              limited to the pricing of the Partner Offering, is accurate and
              not misleading (i.e. free from any price inflation, hidden costs
              or undisclosed terms and conditions) and is in compliance with all
              applicable laws, rules and regulations, guidelines and SAKUBiz
              Policies which the Partner shall observe whether as required by
              SAKUBiz or by the relevant authorities. 
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              II. The Partner is solely responsible for providing the Partner
              Offering, including but not limited to, the supply or performance
              of the Partner Offering, customer services, after-sales-services
              and return management.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              III. Any issues or disputes arising out of or related to the
              supply or performance of the Partner Offering by the Partner to
              the Member shall not affect SAKUBiz’s right to retain all sums
              payable by the Partner to SAKUBiz for the product(s) the Partner
              opted in with SAKUBiz.
            </Typography>
          </ListItem>
        </List>
        <Typography
          variant="body1"
          paragraph
          sx={{ fontWeight: "bold", mt: 2 }}
        >
          (b) In relation to SAKUBiz:
        </Typography>
        <List
          sx={{
            paddingLeft: 2,
            listStyleType: "none",
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              I. SAKUBiz shall provide a Partner account for Partner to access
              to SAKUBiz for business management purposes (“SAKUBiz Account”).
              SAKUBiz shall grant a non-exclusive, non-transferable and
              revocable license or right to the Partner for the use of SAKUBiz.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">II. The Partner shall:</Typography>
          </ListItem>
          <List sx={{ listStyleType: "lower-alpha", paddingLeft: 6 }}>
            <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
              <ListItemText primary="take all reasonable precautions and diligence to prevent any unauthorized use of SAKUBiz; " />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
              <ListItemText primary="keep the user identification and password of its SAKUBiz Account confidential; " />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
              <ListItemText primary="immediately notify SAKUBiz of any unauthorized use of the SAKUBiz Account; " />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
              <ListItemText primary="ensure that the SAKUBiz Account is not shared with and used by any unauthorized person; " />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
              <ListItemText primary="ensure that all information provided in the SAKUBiz Account is complete, accurate, up-to-date and not misleading; " />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
              <ListItemText primary="use the SAKUBiz Account only in accordance with SAKUBiz Terms & Conditions published (and updated from time to time) by SAKUBiz through online; and " />
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
              <ListItemText primary="not use the SAKUBiz Account in a manner which may jeopardize SAKUBiz’s operation or reputation. " />
            </ListItem>
          </List>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              III. The Partner is fully responsible for all activities that
              occur under its SAKUBiz Account even if such activities or uses
              were not committed by the authorized personnel of the Partner.
              SAKUBiz will not be liable for any direct or indirect loss or
              damage arising from unauthorized use of the SAKUBiz Account or the
              Partner’s failure to comply with this Clause 3.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              IV. For enhancement and security purposes, SAKUBiz may from time
              to time conduct software evaluation to assess the performance of
              SAKUBiz. The Partner irrevocably agrees to grant authorization to
              SAKUBiz to assess and review its SAKUBiz Account if so required by
              SAKUBiz due to the foregoing purposes.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              V. SAKUBiz shall have the absolute right to suspend or terminate
              the Partner’s SAKUBiz Account in accordance with Clause 8 below
              without any liability to the Partner.
            </Typography>
          </ListItem>
        </List>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          4) FEES AND TAXES
        </Typography>
        <List
          sx={{
            paddingLeft: 2,
            listStyleType: "none",
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              I. As consideration to participate in any of the listed products
              herein, the Partner has agreed to fees chargeable by PTSB to the
              Partner in respect of the offers/products provided in SAKUBiz
              Programme, which may include (but not limited to) any commission,
              marketing fee, service fee, SMS credit, EDM credit, Whatsapp
              Credit or such other fees imposed (“PTSB Fees”) imposed by PTSB
              for the SAKUBiz Programme from time to time, as set out in the
              respective Products Terms and Conditions.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              II. As consideration for the rights granted to Partner under the
              Agreement, You agree to pay PTSB the requisite monthly fees
              (billed yearly) as set forth on SAKU website. The fees are
              non-refundable. Any taxes that may be levied on the Saku software
              or Partner use of it shall be Partner responsibility.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              III. The PTSB Fees charged under this Agreement is exclusive of
              any sales and services tax (“SST”). Where SST is applicable and
              PTSB is required to remit the SST to the relevant tax authority,
              PTSB may charge SST at the prevailing rate on the invoiced amount
              and the Partner shall pay the invoiced amount inclusive of SST.
            </Typography>
          </ListItem>
        </List>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          5) THE MATTERS RELATING TO PERSONAL INFORMATION
        </Typography>
        <Typography variant="body1" paragraph>
          You hereby agree that We may collect, store, process, disclose
          information access, review and / or use personal information about
          you. We ensure that all personal information submitted by you through
          the SAKUBiz Application and / or our website is well protected and
          encrypted. 
        </Typography>
        <Typography variant="body1" paragraph>
          We agree to keep all personal information private and not to use such
          personal information for any purpose other than for matters related to
          our Services. However, this does not apply to:
        </Typography>
        <List
          sx={{
            paddingLeft: 2,
            listStyleType: "none",
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              I. Prevention of fraudulent acts and / or matters relating to anti
              money laundering and criminal acts of terrorism;
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              II. Personal information that is generally known to the public;
              and
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              III. Such personal information is required to be disclosed by law,
              government or authorized institution under existing regulations.
            </Typography>
          </ListItem>
        </List>
        <Typography variant="body1" paragraph>
          You may only access the Service using authorized means. It is Your
          responsibility to check and ensure that You have downloaded the
          correct SAKUBiz Application for Your device. We are not liable if
        </Typography>
        <Typography variant="body1" paragraph>
          You do not have a compatible device or if You have downloaded the
          wrong version of the SAKUBiz Application to Your device. 
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the rights not to permit You to use the Service should You
          use the SAKUBiz Application with an incompatible or unauthorized
          device or for purpose other than which the SAKUBiz Application is
          intended to be used.
        </Typography>
        <Typography variant="body1" paragraph>
          We do not represent or warrant that:{" "}
        </Typography>
        <List sx={{ listStyleType: "disc", pl: 4 }}>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="The use of the Services and/or SAKUBiz Application will be secured, timely, uninterrupted or error-free or operate in combination with any other hardware, software, system or data; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="Any stored data will be accurate or reliable; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="The services will meet Your requirements or expectations; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="The SAKUBiz Application or the server(s) that make the Service are free of viruses or other harmful components. " />
          </ListItem>
        </List>
        <Typography variant="body1" paragraph>
          By using the SAKUBiz Application, You agree that:
        </Typography>
        <List sx={{ listStyleType: "disc", pl: 4 }}>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will only use the Service for lawful purposes; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will only use the Service for the purpose for which it is intended to be used; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will not use the SAKUBiz Application for fraudulent purposes; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will not use the Service and SAKUBiz Application for purposes other than obtaining the Service; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will not try to harm the Service and SAKUBiz Application in any way whatsoever; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will only use the SAKUBiz Application for Your own use;" />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will keep secure and confidential Your account password or any identification We provide You which allows access to the Service; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will provide the Company with proof of identity as it may reasonably request or require; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You agree to provide accurate, current and complete information as required for the Service and undertake the responsibility to maintain and update Your information in a timely manner to keep it accurate, current and complete at all times during the Terms of Use. You agree that We may rely on Your information as accurate, current and complete. You acknowledge that if Your information is untrue, inaccurate, not current or incomplete in any respect, We have the right but not the obligation to terminate this Terms of Use and Your use of the Service at any time with or without notice; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You will only use an access point or data account which You are authorized to use; " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You agree that the Service is provided on a reasonable effort basis; and " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="You agree that Your use of the Service will be subject to the Our Privacy Policy as may be amended from time to time.  " />
          </ListItem>
        </List>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          6) CONSENT OF COLLECTION AND OWNERSHIP OF DATA
        </Typography>
        <Typography variant="body1" paragraph>
          SAKUbiz and/or its third party provider must collect personal and
          transaction data related to the usage of SAKUbiz made from Reward
          Partner with its users and/or members. To facilitate the collection of
          this data, SAKUbiz may establish data and/or Uniform Resource Locators
          links, and: 
        </Typography>
        <List sx={{ listStyleType: "lower-alpha", paddingLeft: 6 }}>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="the Reward Partner, its employees, and/or appointed agents authorize SAKUbiz and its third party providers to use the Reward Partner’s transaction data as contemplated by and in furtherance of the objectives of the usage of SAKUbiz, including for determining, identifying and tracking Reward Partner, users and members activity;  " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="Reward Partner permits the monitoring, collection, use, disclosure and sharing of SAKUbiz related personal and transaction data for the purpose of enabling the Services;  " />
          </ListItem>
          <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
            <ListItemText primary="Reward Partner consents to the access and processing of personal and transaction data collected from SAKUbiz in connection with the Services, and acknowledges and agrees that SAKUbiz may use such data for purposes of generating anonymous, aggregated, statistical models and compilations, trend analyses, program performance analyses, benchmarking, the development of case studies and for internal business purposes. " />
          </ListItem>
        </List>
        <Typography variant="body1" paragraph>
          All contents of SAKUbiz and services are the property of  SAKUbiz,
          Reward Partner, participating businesses, or advertisers. The
          compilation (i.e., the collection, arrangement, and assembly) of all
          content on SAKUbiz are the exclusive property of  SAKUbiz. The website
          and contents of SAKUbiz may not be copied, distributed, or transmitted
          in any way without the prior written consent of  SAKUbiz. You may not
          modify the content in any way, nor delete any copyright or trademark
          notice. Any information, ideas, suggestions, or communications sent by
          you through SAKUbiz becomes the exclusive property of  SAKUbiz.
          Subject to the terms of the SAKUbiz Privacy Policy, SAKUbiz is
          entitled to use, reproduce, disclose, and distribute any information
          submitted for any purpose without restriction or compensation to you.
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          7) OUR LIABILITIES 
        </Typography>
        <Typography variant="body1" paragraph>
          We employ appropriate and reasonable technical and security measures
          to keep SAKUBiz Application secure and free from virus and error.
          However, as effective as this technology is, no security system is
          impenetrable. We therefore cannot guarantee the security of Our
          database nor can We guarantee that the information You provide will
          not be intercepted while being transmitted to Us.
        </Typography>
        <Typography variant="body1" paragraph>
          The SAKUBiz Application may be subject to limitations, delays, and
          other problems inherent in the use of the internet and electronic
          communications including the device used by You being faulty, not
          connected, out of range, switched off or not functioning. We are not
          responsible for any delays, delivery failures, damages or losses
          resulting from such problems.
        </Typography>
        <Typography variant="body1" paragraph>
          Any problems arising from the use of SAKUBiz Application and/or Our
          website may be reported to Us through the available Customer Service.
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          8) INTELLECTUAL PROPERTY MATTERS 
        </Typography>
        <Typography variant="body1" paragraph>
          SAKUBiz, including its name and logo, SAKUBiz Application and the
          Service, is protected by copyright, trademark and other rights
          provided under the laws of the states and federal of Malaysia. We
          exclusively own all rights, title and interest in and to the SAKUBiz
          Application, including all associated intellectual property rights.
        </Typography>
        <Typography variant="body1" paragraph>
          Without derogating from Our rights under any applicable law or these
          Terms of Use, You are advised that any attempted or actual
          infringement of this provision will result in the termination of all
          Your rights under these Terms of Use. If You circumvent any of the
          means taken by Us to protect the Service from unauthorized use, You
          must immediately cease any and all use of the Service, and You
          undertake to do so.  
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          9) FORCE MAJEURE
        </Typography>
        <List
          sx={{
            paddingLeft: 2,
            listStyleType: "none",
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              I. Both Parties within this Agreement are released from
              responsibility to all obligations and delay of work as consequence
              of Force Majeure, as defined in Clause 1.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              II. If one of the Parties has delayed or is prevented from
              performing its obligations hereunder as a result of an event of
              Force Majeure, it shall promptly notify the other Party in writing
              as soon as possible after the occurrence of such an event of Force
              Majeure.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              III. If the performance by any Party of any of its obligations
              under this Agreement is affected by Force Majeure for a continuous
              period in excess of six (6) months after notice is given, the
              Parties shall enter into bona fide discussions with a view to
              alleviating its effects, or to agreeing upon such alternative
              arrangements as may be fair and reasonable.
            </Typography>
          </ListItem>
        </List>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          10) INDEMNIFICATION AND LIABILITY
        </Typography>
        <List
          sx={{
            paddingLeft: 2,
            listStyleType: "none",
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              I. Notwithstanding anything else in this Agreement, neither PTSB
              nor its Representatives shall be liable to the Partner and/or to
              any third party for any consequential, incidental, indirect,
              punitive and/or special damages, including commercial loss and
              loss of profits, howsoever caused which directly or indirectly
              arises under this Agreement even if PTSB has been advised of the
              possibility or likelihood of such occurring.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              II. The Partner shall indemnify PTSB and its Representatives
              against any and all losses, liabilities, damages, claims, fines,
              suits and the costs and expenses incurred or suffered by PTSB and
              its Representatives as a result of any claims or proceedings
              brought by any third party due to the negligence, omission or
              willful default of the Partner or its Representatives.
            </Typography>
          </ListItem>
        </List>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          11) TERMINATION 
        </Typography>
        <List
          sx={{
            paddingLeft: 2,
            listStyleType: "none",
          }}
        >
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              I. We reserve the right to immediately suspend, restrict or
              terminate these Terms of Use and Your use of the SAKUBiz
              Application if We have reasonable grounds to suspect that You have
              breached or violated any provision of these Terms of Use or the
              prevailing laws and regulations. 
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              II. This Agreement shall commence on the Effective Date and remain
              in effect for one (1) year (the “Term”) after which this Agreement
              will renew automatically continuously with the same terms and
              conditions for another Term equivalent to the Term specified
              herein upon the expiry of each Term, unless either Party submits a
              written request to terminate the Agreement or both Parties agree
              to amend the terms and conditions of this Agreement under a
              supplemental agreement no less than thirty (30) Business Days
              prior to the expiry of this
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              III. Any notice required hereunder shall be made in writing and
              sent via electronic mail or delivered to the addresses to the
              relevant Party’s registered and/or business address as set forth
              in the Registration /SAKUBiz portal
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              IV. Notices shall be effective upon delivery, provided it occurs
              during the addressee’s business hours; otherwise it will only take
              effect on the next Business Day in the addressee’s state or
              federal territory.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: "list-item" }}>
            <Typography variant="body1">
              V. All notices sent via electronic mail shall be sent to the
              official email addresses of the Parties and shall be deemed
              delivered save and except that any email indicating failure of
              delivery is automatically generated to the sender.
            </Typography>
          </ListItem>
        </List>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          12) ENTIRE AGREEMENTS
        </Typography>
        <Typography variant="body1" paragraph>
          The Partner Registration Form, together with the General Terms and
          Conditions, Products Terms and Conditions (as introduced by PTSB and
          accepted by the Partner from time to time, whether physically or
          through online) and any appendices, annexures and/or other additional
          documents referred to in this General Terms and Conditions and the
          respective Products Terms and Conditions, including but not limited to
          any future amendment or addendum made by PTSB, as the case may be,
          shall constitute the entire Partner Agreement between PTSB and the
          Partner. For the avoidance of doubt, this Agreement contains all the
          terms agreed between the Parties regarding its subject matter and
          supersedes and excludes any prior agreement, understanding or
          arrangement between the Parties, whether oral or in writing.
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          13) PRODUCTS TERMS & CONDITIONS
        </Typography>
        <Typography variant="body1" paragraph>
          During the Term of this Agreement, PTSB may from time to time
          introduce a new product to the Partner and the Partner may from time
          to time agree to opt in for such product by signing the relevant
          Product Terms and Conditions, whether physically or through online.
          For the avoidance of doubt, all Products Terms and Conditions executed
          between the Parties shall be in addition to but not in diminution of
          this General Terms and Conditions. Unless otherwise stated in the
          respective Products Terms and Conditions, this General Terms and
          Conditions shall prevail if there is any inconsistency between a
          Product Terms and Conditions and this General Terms and Conditions.
        </Typography>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          14) MISCELLANEOUS
        </Typography>
        <Typography variant="body1" paragraph>
          Our waiver or forbearance of failure to claim a breach of any
          provision of these Terms of Use or to exercise any right provided by
          these Terms of Use or the applicable law, may not be deemed to
          constitute a waiver with respect to any subsequent breach of any
          provision hereof.
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms of Use are drawn up, which versions will be binding on You
          and Us. 
        </Typography>
        <Typography variant="body1" paragraph>
          You may not transfer or assign Your rights under these Terms of Use,
          without our prior written approval. 
        </Typography>
        <Typography variant="body1" paragraph>
          If any terms under these Terms of Use is held to be illegal, invalid
          or unenforceable, in whole or in part, under any enactment or rule of
          law, the term or part of it will, to that extent, be deemed not to
          form part of these Terms of Use but the legality, validity or
          enforceability of the remainder of these Terms of Use will not be
          affected.
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms of Use are governed by and to be construed under the laws
          of any jurisdiction where the SAKUBiz Application is used. Any
          disputes arising from the Terms of Use shall be resolved by
          Arbitration under Malaysia’s arbitration rules – Asian International
          Arbitration Center (AIAC)
        </Typography>
      </Box>
    </Container>
  );
};

export default MerchantTerms;
