import { memo } from "react";
import PropTypes from "prop-types";
import styles from "./SoHowCanItBeEasier.module.css";

const SoHowCanItBeEasier = memo(({ className = "" }) => {
  return (
    <section className={[styles.section, className].join(" ")}>
      <div className={styles.textTitlePage}>
        <h1 className={styles.textTitlePage1}>So how can it be easier?</h1>
      </div>
      <div className={styles.doubleTilesContent}>
        <div className={styles.tiles}>
          <div className={styles.text}>
            <div className={styles.text1}>
              <p
                className={styles.digitalIdEnrolment}
              >{`Digital ID enrolment `}</p>
              <p className={styles.digitalIdEnrolment}>(Phone number, UID)</p>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.text1}>
              <p
                className={styles.digitalIdEnrolment}
              >{`Self serve platform `}</p>
              <p className={styles.digitalIdEnrolment}>for merchants</p>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.text1}>
              <p
                className={styles.digitalIdEnrolment}
              >{`Wide array of merchants `}</p>
              <p className={styles.digitalIdEnrolment}>
                that are lifestyle focused
              </p>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.text1}>
              Single platform for all loyalty programs / digital cards
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.text1}>Must be data rich and insightful</div>
          </div>
        </div>
        <img
          className={styles.graphicIcon}
          
          alt=""
          src="/graphic-3.svg"
        />
        <div className={styles.tiles}>
          <div className={styles.text}>
            <div className={styles.text1}>
              Diversity in offerings whether points, cashback, journey stamps,
              promo codes
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.text1}>
              Different channels of communication
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.text1}>
              <p
                className={styles.digitalIdEnrolment}
              >{`The right segmentation of `}</p>
              <p className={styles.digitalIdEnrolment}>
                users and the automation
              </p>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.text1}>
              <p
                className={styles.digitalIdEnrolment}
              >{`Must be SME-fordable `}</p>
              <p className={styles.digitalIdEnrolment}>(right range of fees)</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

SoHowCanItBeEasier.propTypes = {
  className: PropTypes.string,
};

export default SoHowCanItBeEasier;
