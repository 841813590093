import { memo } from "react";
import PropTypes from "prop-types";
import styles from "./MemberBrand.module.css";

const MemberBrand = memo(({ className = "" }) => {
  return (
    <section className={[styles.section, className].join(" ")}>
      <div className={styles.header}>
        <div className={styles.textTitlePage}>
          <h2 className={styles.textTitlePage1}>Driving value to both</h2>
        </div>
        <div className={styles.textTitlePage2}>
          <h1 className={styles.textTitlePage3}>Member + Brand</h1>
        </div>
      </div>
      <img
        className={styles.memberBrandSaku1}
        
        alt=""
        src="/member-brand-saku-1@2x.webp"
      />
      <div className={styles.tiles}>
        <div className={styles.tile}>
          <div className={styles.text}>
            <div className={styles.keyPoint}>
              <p className={styles.convenience}>Convenience</p>
              <p className={styles.multipleTailoredRewards}>
                Multiple tailored rewards program
              </p>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.keyPoint}>
              <p className={styles.convenience}>Tiered Membership</p>
              <p className={styles.multipleTailoredRewards}>
                Added into a rewards programme that rewards the more you
                spend/visit
              </p>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.keyPoint}>
              <p className={styles.convenience}>Array of Offers</p>
              <p className={styles.multipleTailoredRewards}>
                Availability in offering points, cashback, journey stamps,
                vouchers or promo codes
              </p>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.keyPoint}>
              <p className={styles.convenience}>Gifting</p>
              <p className={styles.multipleTailoredRewards}>
                Refer your favorite brands to anyone and receive something in
                return
              </p>
            </div>
          </div>
        </div>
        <div className={styles.tile1}>
          <div className={styles.text}>
            <div className={styles.keyPoint}>
              <p className={styles.convenience}>
                Affordable Entry Into Loyalty
              </p>
              <p className={styles.multipleTailoredRewards}>
                Easy for brands to enrol into one stop loyalty solution that is
                affordable for business needs whether it is small, medium or
                large
              </p>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.keyPoint}>
              <p className={styles.convenience}>Self-Serve</p>
              <p className={styles.multipleTailoredRewards}>
                Customize it based on what you want to achieve with the program
                – whether newbies, new segments, new type of offerings etc
              </p>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.keyPoint}>
              <p className={styles.convenience}>Data Insights</p>
              <p className={styles.multipleTailoredRewards}>
                Generating insights into Saku’s consumer behaviour to support
                our brands in better understanding consumer behaviour and needs
              </p>
            </div>
          </div>
          <div className={styles.text14}>
            <div className={styles.text15}>
              <p className={styles.marketingAsA}>
                Marketing as a Service (MaaS)
              </p>
              <p
                className={styles.convenience}
              >{`Leveraging our ecosystem to `}</p>
              <p className={styles.convenience}>
                {" "}
                support our partners in strategising
              </p>
              <p className={styles.convenience}>
                {" "}
                and executing marketing campaigns
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.tiles1}>
        <div className={styles.left}>
          <img
            className={styles.memberSaku1}
            alt=""
            src="/member-saku-1@2x.webp"
          />
          <img
            className={styles.graphicIcon}
            
            alt=""
            src="/graphic3@2x.webp"
          />
          <div className={styles.header}>
            <div className={styles.textTitlePage}>
              <h2 className={styles.textTitlePage1}>What is Saku for the</h2>
            </div>
            <div className={styles.textTitlePage6}>
              <h1 className={styles.textTitlePage7}>Member</h1>
            </div>
          </div>
          <div className={styles.tile2}>
            <div className={styles.text}>
              <div className={styles.keyPoint}>
                <p className={styles.oneStepRegistration}>
                  One-Step Registration
                </p>
                <p
                  className={styles.convenience}
                >{`Create your profile with `}</p>
                <p className={styles.convenience}> just your mobile number!</p>
              </div>
            </div>
            <div className={styles.text}>
              <div className={styles.keyPoint}>
                <p className={styles.oneStepRegistration}>Virtual Cards</p>
                <p
                  className={styles.convenience}
                >{`Flash your Virtual Cards’ QR at all `}</p>
                <p
                  className={styles.convenience}
                >{`Brands’/ Merchants’ outlets `}</p>
                <p className={styles.convenience}>{` & collect Points`}</p>
              </div>
            </div>
            <div className={styles.text20}>
              <div className={styles.keyPoint}>
                <p className={styles.convenience}>
                  All your cards in one place
                </p>
                <p className={styles.multipleTailoredRewards}>
                  Agnostic to all loyalty programmes 
                </p>
              </div>
            </div>
            <div className={styles.text20}>
              <div className={styles.keyPoint}>
                <p className={styles.convenience}>Transfer Rewards</p>
                <p
                  className={styles.multipleTailoredRewards}
                >{`Redeem vouchers from your favorite catalogue & transfer them to anyone you want to! Or refer someone to your favorite brands all in a single platform`}</p>
              </div>
            </div>
            <div className={styles.text20}>
              <div className={styles.keyPoint}>
                <p className={styles.convenience}>Be Prompted</p>
                <p className={styles.multipleTailoredRewards}>
                  Be prompted via your usual favorite channels on offers, news
                  and promotions for the brands you want to hear from
                </p>
              </div>
            </div>
            <div className={styles.text26}>
              <div className={styles.subpoint} />
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <img
            className={styles.brandSaku1}
            alt=""
            src="/brand-saku-1@2x.webp"
          />
          <img
            className={styles.graphicIcon1}
            
            alt=""
            src="/graphic-12.svg"
          />
          <div className={styles.header}>
            <div className={styles.textTitlePage}>
              <h2 className={styles.textTitlePage1}>What is Saku for the</h2>
            </div>
            <div className={styles.textTitlePage10}>
              <h1 className={styles.textTitlePage7}>Brand</h1>
            </div>
          </div>
          <div className={styles.keyPoints}>
            <div className={styles.text}>
              <div className={styles.keyPoint}>
                <p className={styles.convenience}>Easy participation</p>
                <p className={styles.multipleTailoredRewards}>
                  Subscribe on a monthly basis to reach your members
                </p>
              </div>
            </div>
            <div className={styles.text}>
              <div className={styles.keyPoint}>
                <p className={styles.convenience}>Redemption</p>
                <p className={styles.multipleTailoredRewards}>
                  Be able to have your members redeem for items and vouchers on
                  our platform!
                </p>
              </div>
            </div>
            <div className={styles.text}>
              <div className={styles.keyPoint}>
                <p className={styles.convenience}>Timing</p>
                <p className={styles.multipleTailoredRewards}>
                  Help you solve your online to offline problems by reaching
                  your customers at the right time and moment
                </p>
              </div>
            </div>
            <div className={styles.text}>
              <div className={styles.keyPoint}>
                <p className={styles.convenience}>Care Free</p>
                <p className={styles.multipleTailoredRewards}>
                  Easy to use and entirely customizable and self serve
                </p>
              </div>
            </div>
            <div className={styles.text}>
              <div className={styles.keyPoint}>
                <p className={styles.convenience}>Personalisation</p>
                <p className={styles.multipleTailoredRewards}>
                  Use your brand’s card and not another loyalty brand and
                  actually know what and who your customers are (and habits)
                </p>
              </div>
            </div>
            <div className={styles.text}>
              <div className={styles.keyPoint}>
                <p className={styles.convenience}>Automate</p>
                <p className={styles.multipleTailoredRewards}>
                  We will use software and hardware to help you reach the right
                  customers at right moment
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

MemberBrand.propTypes = {
  className: PropTypes.string,
};

export default MemberBrand;
