import { useCallback, useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Swal from "sweetalert2";

const UseReCaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Execute recaptcha not yet available.",
      });
      return;
    }

    const token = await executeRecaptcha("payment");
    return token;
  }, [executeRecaptcha]);

  return { handleReCaptchaVerify };
};

export default UseReCaptcha;
