import React from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import Header from "./HeaderFooter/Header";
import ContactOurTeam from "./HomeSolutionAboutUs/ContactOurTeam";
import Footer from "./HomeSolutionAboutUs/Footer";

const Layout = ({ children }) => {
  const location = useLocation(); // Get the current location

  // Check if the current URL is "/Blog"
  const isBlogPage = location.pathname === "/Blog";

  return (
    <div>
      <Header />
      <main className="saku-main-content">{children}</main>
      {/* Conditionally render ContactOurTeam and Footer */}
      {!isBlogPage && (
        <>
          <ContactOurTeam
            graphics="/graphics.svg"
            text="Contact our team"
            buttonStart
          />
          <Footer pocket="/pocket.svg" saku="/saku.svg" />
        </>
      )}
    </div>
  );
};

export default Layout;
