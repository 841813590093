import { memo } from "react";
import PropTypes from "prop-types";
import styles from "./WhatIsLoyalty.module.css";

const WhatIsLoyalty = memo(({ className = "" }) => {
  return (
    <section className={[styles.section, className].join(" ")}>
      <div className={styles.textTitlePage}>
        <h1 className={styles.textTitlePage1}>What is loyalty?</h1>
      </div>
      <div className={styles.tiles}>
        <div className={styles.tile}>
          <div className={styles.circle}>
            <div className={styles.text}>
              <div className={styles.selfConfidenceValue}>
                Success of any business is determined by loyal customers who
                develop a preference for that business.
              </div>
            </div>
          </div>
          <img className={styles.sakuGuy11} alt="" src="/saku-guy-1-1@2x.webp" />
          <img
            className={styles.selfConfidencebroIcon}
            
            alt=""
            src="/selfconfidencebro.svg"
          />
        </div>
        <div className={styles.tile}>
          <div className={styles.circle1}>
            <div className={styles.text}>
              <div className={styles.selfConfidenceValue}>
                These customers did not become loyal purely due to prices, but
                rather as a result of a positive feeling or perception about the
                business.
              </div>
            </div>
          </div>
          <img
            className={styles.sakuGuy111}
            alt=""
            src="/saku-guy-1-11@2x.webp"
          />
          <img
            className={styles.brandConstructionbroIcon}
            
            alt=""
            src="/brandconstructionbro.svg"
          />
        </div>
        <div className={styles.tile}>
          <div className={styles.circle2}>
            <div className={styles.text}>
              <div className={styles.selfConfidenceValue}>
                It is suggested that higher customer satisfaction will translate
                into higher customer loyalty.
              </div>
            </div>
          </div>
          <img
            className={styles.sakuGuy11}
            alt=""
            src="/saku-guy-1-12@2x.webp"
          />
          <img
            className={styles.onlinePopularitybroIcon}
            
            alt=""
            src="/onlinepopularitybro.svg"
          />
        </div>
      </div>
    </section>
  );
});

WhatIsLoyalty.propTypes = {
  className: PropTypes.string,
};

export default WhatIsLoyalty;
