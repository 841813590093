import React from "react";
import { Container, Typography, Box } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container
      sx={{
        backgroundColor: "#f7f9fc", // Light background to make it stand out
        padding: "16px",
        borderRadius: "8px", // Rounded corners for a modern feel
        mb: 3,
        borderLeft: "6px solid var(--secondary-color-3)", // Accent color for definition box
        borderRight: "6px solid var(--secondary-color-3)", // Accent color for definition box
        // Add responsive styling using breakpoints
        marginTop: "100px", // Space at the top for all screen sizes
        marginLeft: "auto",
        marginRight: "auto", // Center the container horizontally
        // For mobile devices, add space on the left and right (using `xs` breakpoint)
        maxWidth: {
          xs: "95%", // 95% width for mobile screens
          sm: "80%", // 80% width for tablets and small screens
          md: "60%", // 60% width for larger screens
        },
      }}
    >
      <Box>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          Privacy Policy
        </Typography>
        <p className="text-align-justify">
          The following Privacy Policy describes how we collect, store, use,
          transfer, disclose and protect Your personal information which can be
          identified and obtained through SAKU Application and/or our website.
          Please read this Privacy Policy thoroughly to ensure that You
          understand how We apply this Privacy Policy.
        </p>
        <p className="text-align-justify">
          Your use of SAKU Application and services is subject to the Terms of
          Use and this Privacy Policy and indicates Your consent over the terms
          of Use and Privacy Policy.
        </p>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          1. DEFINITION
        </Typography>
        <p className="text-align-justify">
          In this Privacy Policy, unless expressly provided otherwise, the
          following expressions have the following meanings:
        </p>
        <ul>
          <li className="text-align-justify">
            “You” means the party registered as a SAKU Application user to use
            the Services provided in the SAKU Application.
          </li>
          <li className="text-align-justify">
            “SAKU Application” is a mobile app with the name SAKU for Loyalty
            Memberships, which can be downloaded via playstore for Android users
            as well as through via app store for iOS users that We develop to
            support Our Services provided.
          </li>
          <li className="text-align-justify">
            “Personal Information” means information regarding Yourself which
            can be privately identified and collected through the SAKU
            Application, such as name, address, date of birth and occupation (if
            You are a person), corporate data and identity document (if You are
            not a person), phone number, email address and other information
            which may identify You as an user of SAKU Application.
          </li>
          <li className="text-align-justify">
            “We/Our/Us” means SAKU, a company established under the laws of the
            states and federal of Malaysia.
          </li>
          <li className="text-align-justify">
            “Terms of Use” means terms and condition or standard operational
            procedures or other terms in connection with SAKU Application or
            website developed by Us.
          </li>
          <li className="text-align-justify">
            “Service” means various services offered by SAKU Application or
            website.
          </li>
          <li className="text-align-justify">
            “Customer Service” is the customer service center We provide for You
            and can be reached by email{" "}
            <a href="mailto:feedback@saku.my">feedback@saku.my</a> and can be
            used when You have problems in the use of SAKU Application or when
            you use Our Service.
          </li>
          <li className="text-align-justify">
            “Cookie” is a small data file placed in Your browser on Your
            internet device. With cookie, the feature of SAKU Application and/or
            website that You access can save information or remember Your
            actions and preferences from time to time.
          </li>
          <li className="text-align-justify">
            “Website” means Our website at{" "}
            <a href="https://saku.my/">https://saku.my/</a> or other sites which
            We developed officially to support the Service ecosystem.
          </li>
        </ul>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          2. INFORMATION WHICH WE COLLECT
        </Typography>
        <p className="text-align-justify">
          We collect Personal Information from You so that the SAKU Application
          can perform its function in connection with the Service We provide.
          You can provide Personal Information directly or collected
          automatically when You use the SAKU Application.
        </p>
        <p className="text-align-justify">
          Information that You provide directly When registering to the SAKU
          Application, You will provide to Us certain Personal Information
          pursuant to Terms of Use required by each type and function of the
          Application.
        </p>
        <p className="text-align-justify">
          Information which We collect when You use the Application To utilize
          the Services We provide to You, You provide Us with information as
          relevant, including but not limited to information such as Your name,
          address and email address. When You use SAKU Application, We will also
          process Your technical data such as, internet protocol address,
          internet device identity (ID) or media access control address, and
          information regarding the manufacturer, model and operating system of
          the device that You use to access the SAKU Application. We use these
          data to allow Us to send the SAKU Application’s functions, to resolve
          technical difficulties, to provide You the correct and updated version
          of the SAKU Application as well as to improve the SAKU Application’s
          function.
        </p>
        <p className="text-align-justify">
          Use of Cookie We use cookie for several purposes. We use it, for
          example, to remember Your safe search preferences, to help You to use
          the Service, and to protect Your data.{" "}
        </p>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          3. THE USE OF INFORMATION WHICH WE COLLECT
        </Typography>
        <p className="text-align-justify">
          We use Your email address, name, phone number to verify Your ownership
          over an account in SAKU Application, to communicate with You in
          connection with the use of the Service and to give You information
          regarding the SAKU Application. We may also use Your name, email
          address and phone number to send message and/or make phone calls to
          provide special offers or promotions that are being held (both by Us
          and other parties who have collaborated with Us), providing general
          updates on the SAKU Application.{" "}
        </p>
        <p className="text-align-justify">
          We use Your Personal Information entirely to analyze the SAKU
          Application’s pattern of use. You hereby agree that Your data will be
          used by Our internal data processing system to ensure the delivery of
          the best function of the Application to You.{" "}
        </p>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          4. SHARING OF INFORMATION WHICH WE COLLECT
        </Typography>
        <ul>
          <li className="text-align-justify">
            We may employ or cooperate with companies and persons as third
            parties to facilitate or provide support in developing the SAKU
            Application and certain services to Us and/or on Our behalf, to,
            among others:
            <ol>
              <li className="text-align-justify">
                provide customer’s assistance;
              </li>
              <li className="text-align-justify">
                perform Services relating to website (including but not limited
                to maintenance, database processing, web analysis and website
                feature perfecting services);
              </li>
              <li className="text-align-justify">
                assist Us in analyzing how the SAKU Application and Services are
                used as well as their development;
              </li>
              <li className="text-align-justify">
                to assist Our professional advisor, including legal advisor,
                financial advisor, and consultants. These third parties have
                access over Your Personal Information only to perform those
                tasks for Us and/or on Our behalf and the third parties are
                contractually bound not to disclose or use the Personal
                Information for any other purpose.
              </li>
            </ol>
          </li>
          <li className="text-align-justify">
            We will disclose Your Personal Information to the extent it is
            required by law or required to comply with the provisions of the
            laws and regulations, government institution, or in case of a
            dispute, or any form of legal proceeding between You and Us, or
            between You and other user in connection with, or relating to the
            Service.
          </li>
          <li className="text-align-justify">
            Your Personal Information may be transferred, stored, used, and
            processed in a jurisdiction other than Indonesia where Our servers
            are located.
          </li>
          <li className="text-align-justify">
            We may eliminate certain parts of Your Personal Information which
            may identify You (so the Personal Information becomes anonymous) and
            disclose the anonymous data to a third party for the purposes of
            development the SAKU Application, database management, Service’s
            analysis or improvement. We also may combine Your Personal
            Information with other information in a way that the information is
            no longer associated with You, and later disclose the combined
            information to the third party, for the above-mentioned purposes.
          </li>
        </ul>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          5. SECURITY
        </Typography>
        <p className="text-align-justify">
          Confidentiality of your data and Personal Information is the most
          important matter for Us. We will conduct the best effort and steps to
          protect and secure Your data and Personal Information. However, We
          cannot fully guarantee that Our system is totally impenetrable by
          virus, malware, disruption or extraordinary occurrence including
          unauthorized access by a third party. You may not disclose Your
          account password to anyone and must always maintain the security of
          the device You use.
        </p>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          6. CHANGES TO THIS PRIVACY POLICY
        </Typography>
        <p className="text-align-justify">
          We may change in this Privacy Policy to reflect the changes in Our
          activity. We advise You to visit this page periodically to obtain the
          latest information regarding how We enforce this Privacy Policy.
        </p>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          7. ACKNOWLEDGEMENT AND CONSENT
        </Typography>
        <p className="text-align-justify">
          By using SAKU Application and/or Our website, You acknowledge that You
          have read and understood this Privacy Policy and the Terms of Use and
          agree and accede to the use, practice, processing and transfer of Your
          Personal Information by Us as stated in this Privacy Policy. You also
          represent that You are entitled to share all of the information You
          have provided to Us and to give Us the power to use and share such
          information to support and perform the function of SAKU Application.
        </p>

        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold", mt: 3 }}
        >
          8. MISCELLANEOUS
        </Typography>
        <ul>
          <li className="text-align-justify">
            This Privacy Policy shall be regulated and interpreted pursuant to
            the laws of any jurisdiction where the SAKU Application is used. Any
            dispute arising from this Privacy Policy shall be resolved by
            Arbitration under Malaysia’s arbitration rules – Asian International
            Arbitration Center (AIAC).
          </li>
          <li className="text-align-justify">
            If You have further questions regarding Your Privacy and information
            security or Your wish to update or delete Your data, please contact
            Our Customer Service at:{" "}
            <a href="mailto:feedback@saku.my">feedback@saku.my</a>
          </li>
        </ul>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
